<template>
  <div class="footer">
    <div class="block_21 flex-row">
      <div class="text-group_58 flex-col justify-between">
        <span class="text_67">开启建筑数字化孪生</span>
        <span class="text_68">
          提供全面的能源相关服务与支持，包括设备安装、维护，故障排除，电路设计和优化。&nbsp;提供专业团队将确保您的能源系统始终高效运行，并提供快速响应和可靠解决方案，以满足您的需求。
        </span>
      </div>
      <div class="text-wrapper_18 flex-col" @click="goCooperation">
        <span class="text_69">渠道合作</span>
      </div>
      <div class="text-wrapper_19 flex-col" @click="sendMessage">
        <span class="text_70">立即体验</span>
      </div>
    </div>
    <div class="block_2 flex-col">
      <div class="box_30 flex-row">
        <div class="block_48 flex-col justify-between">
          <div class="text-wrapper_45 flex-row justify-between">
            <span class="text_12" @click="goProductCenter('nywlwyjcp', 'yjall')"
              >产品中心</span
            >
            <span class="text_13" @click="goProductCenter('nyszhjjfa', 'rjall')"
              >解决方案</span
            >
          </div>
          <div class="text-group_50 flex-col justify-between">
            <span class="text_14">联系电话</span>
            <span class="text_15">021-3988&nbsp;2683</span>
          </div>
        </div>
        <div class="block_49 flex-col justify-between">
          <div class="text-wrapper_46 flex-row justify-between">
            <span class="text_13" @click="goServiceCase">标杆案例</span>
            <span class="text_16" @click="goCooperation">渠道合作</span>
            <!-- <span class="text_16"></span> -->
            <!-- <span class="text_16">云平台</span> -->
          </div>
          <div class="text-group_51 flex-col justify-between">
            <span class="text_18">全国热线</span>
            <span class="text_19">177&nbsp;2132&nbsp;3870</span>
          </div>
        </div>
        <div class="block_50 flex-col justify-between">
          <div class="text-wrapper_47 flex-row justify-between">
            <span class="text_17" @click="goComPanyIntro">关于我们</span>
          </div>
          <div class="text-group_52 flex-col justify-between">
            <span class="text_22">江浙沪热线</span>
            <span class="text_23">139&nbsp;1881&nbsp;2305</span>
          </div>
        </div>
        <div class="block_51 flex-col justify-between">
          <span class="text_24"></span>
          <div class="text-group_53 flex-col justify-between">
            <span class="text_25">公司地址</span>
            <span class="text_26">上海市奉贤区浦星公路9688弄6号6幢二层</span>
          </div>
        </div>
        <div class="image-wrapper_1 flex-col">
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="./assets/footter/ewm.png"
          />
        </div>
      </div>
      <div class="email_cls flex-col justify-between">
        <span class="text_14">投递邮箱</span>
        <span class="text_15">puwo888@163.com</span>
      </div>
      <div class="box_2 flex-col"></div>
      <div class="text-wrapper_48 flex-row justify-between">
        <span class="text_27">
          Copyright&nbsp;&#64;&nbsp;
          {{
            new Date().getFullYear()
          }}.&nbsp;All&nbsp;rights&nbsp;reserved.&nbsp;版权所有.&nbsp;上海扑沃智能科技有限公司
        </span>
        <span class="text_28">沪ICP备18037633号</span>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "./pcbus.js";
export default {
  name: "Footer",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    sendMessage() {
      bus.$emit("pcshowExperEvent", true);
    },
    goCooperation() {
      this.$router.push({
        name: "Cooperation",
      });
    },
    goProductCenter(val, val1) {
      this.$router.push({
        name: "Products",
        params: {
          curFatherType: val,
          curSonType: val1,
        },
      });
    },
    goIndex() {
      this.$router.push("/");
    },
    goId(val) {
      this.$router.push("/");
      setTimeout(() => {
        document.querySelector("#" + val).scrollIntoView(true);
      }, 500);
    },
    goServiceCase() {
      this.$router.push("/service-case");
    },
    goComPanyIntro() {
      this.$router.push("/company-intro");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  // width: 100%;
  // height: 300px;
  // background-image: url(../assets/image/footer25.png);
  // box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.6);
  // box-sizing: border-box;

  .block_21 {
    background-image: url(./assets/footter/bg1.png);
    width: 99.95vw;
    height: 13.65vw;
    justify-content: flex-center;
  }

  .text-group_58 {
    width: 39.17vw;
    height: 5.11vw;
    margin: 4.27vw 0 0 12.5vw;
  }

  .text_67 {
    width: 13.49vw;
    height: 2.09vw;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 1.45vw;
    letter-spacing: 0.699999988079071px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    line-height: 2.09vw;
  }

  .text_68 {
    width: 39.17vw;
    height: 2.3vw;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 0.83vw;
    letter-spacing: 0.4000000059604645px;
    font-weight: NaN;
    text-align: left;
    line-height: 1.15vw;
    margin-top: 0.73vw;
  }

  .text-wrapper_18 {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 21px;
    height: 2.19vw;
    border: 2px solid rgba(255, 255, 255, 1);
    width: 9.59vw;
    margin: 5.72vw 0 0 14.84vw;
    cursor: pointer;
  }

  .text_69 {
    width: 3.44vw;
    height: 1.15vw;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 0.83vw;
    letter-spacing: 0.4000000059604645px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 1.15vw;
    margin: 0.52vw 0 0 3.07vw;
  }

  .text-wrapper_19 {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 21px;
    height: 2.19vw;
    width: 9.59vw;
    margin: 5.72vw 12.44vw 0 1.82vw;
    cursor: pointer;
  }

  .text_70 {
    width: 3.44vw;
    height: 1.15vw;
    overflow-wrap: break-word;
    color: rgba(52, 57, 192, 1);
    font-size: 0.83vw;
    letter-spacing: 0.4000000059604645px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 1.15vw;
    margin: 0.52vw 0 0 3.07vw;
  }

  .block_2 {
    background-color: rgba(0, 0, 0, 1);
    width: 99.95vw;
    height: 19vw;
  }

  .box_30 {
    width: 74.69vw;
    height: 7.4vw;
    margin: 1.66vw 0 0 12.5vw;
  }

  .block_48 {
    width: 10vw;
    height: 6.46vw;
    margin-top: 0.94vw;
  }

  .text-wrapper_45 {
    width: 10vw;
    height: 1.1vw;
  }

  .text_12 {
    width: 3.44vw;
    height: 1.1vw;
    overflow-wrap: break-word;
    color: rgba(211, 211, 211, 1);
    font-size: 0.83vw;
    letter-spacing: 0.4000000059604645px;
    font-family: OPPOSans-R;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 1.1vw;
    cursor: pointer;
  }

  .text_13 {
    width: 3.44vw;
    height: 1.1vw;
    overflow-wrap: break-word;
    color: rgba(211, 211, 211, 1);
    font-size: 0.83vw;
    letter-spacing: 0.4000000059604645px;
    font-family: OPPOSans-R;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    line-height: 1.1vw;
  }

  .text-group_50 {
    width: 8.96vw;
    height: 2.77vw;
    margin-top: 2.61vw;
  }

  .text_14 {
    width: 3.03vw;
    height: 1.05vw;
    overflow-wrap: break-word;
    color: rgba(156, 156, 156, 1);
    font-size: 0.72vw;
    letter-spacing: 0.3499999940395355px;
    font-family: Montserrat-Regular;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    line-height: 0.94vw;
  }

  .text_15 {
    width: 8.96vw;
    height: 1.41vw;
    overflow-wrap: break-word;
    color: rgba(81, 140, 255, 1);
    font-size: 1.14vw;
    letter-spacing: 0.550000011920929px;
    font-family: Montserrat-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    line-height: 1.41vw;
    margin-top: 0.32vw;
  }

  .block_49 {
    width: 9.43vw;
    height: 6.46vw;
    margin: 0.93vw 0 0 2.86vw;
  }

  .text-wrapper_46 {
    width: 9.17vw;
    height: 1.1vw;
    margin-left: 0.27vw;
  }

  .text_16 {
    width: 2.61vw;
    height: 1.1vw;
    overflow-wrap: break-word;
    color: rgba(211, 211, 211, 1);
    font-size: 0.83vw;
    letter-spacing: 0.4000000059604645px;
    font-family: OPPOSans-R;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    line-height: 1.1vw;
  }

  .text_17 {
    width: 3.44vw;
    height: 1.1vw;
    overflow-wrap: break-word;
    color: rgba(211, 211, 211, 1);
    font-size: 0.83vw;
    letter-spacing: 0.4000000059604645px;
    font-family: OPPOSans-R;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    line-height: 1.1vw;
  }

  .text-group_51 {
    width: 8.86vw;
    height: 2.77vw;
    margin-top: 2.61vw;
  }

  .text_18 {
    width: 3.03vw;
    height: 1.05vw;
    overflow-wrap: break-word;
    color: rgba(156, 156, 156, 1);
    font-size: 0.72vw;
    letter-spacing: 0.3499999940395355px;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
    line-height: 1.05vw;
  }

  .text_19 {
    width: 8.86vw;
    height: 1.41vw;
    overflow-wrap: break-word;
    color: rgba(81, 140, 255, 1);
    font-size: 1.14vw;
    letter-spacing: 0.550000011920929px;
    font-family: Montserrat-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 1.41vw;
    cursor: pointer;
    margin-top: 0.32vw;
  }

  .block_50 {
    width: 10vw;
    height: 6.46vw;
    margin: 0.93vw 0 0 3.12vw;
  }

  .text-wrapper_47 {
    width: 10vw;
    height: 1.1vw;
  }

  .text_20 {
    width: 3.44vw;
    height: 1.1vw;
    overflow-wrap: break-word;
    color: rgba(211, 211, 211, 1);
    font-size: 0.83vw;
    letter-spacing: 0.4000000059604645px;
    font-family: OPPOSans-R;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 1.1vw;
    cursor: pointer;
  }

  .text_21 {
    width: 3.44vw;
    height: 1.1vw;
    overflow-wrap: break-word;
    color: rgba(211, 211, 211, 1);
    font-size: 0.83vw;
    letter-spacing: 0.4000000059604645px;
    font-family: OPPOSans-R;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 1.1vw;
  }

  .text-group_52 {
    width: 8.86vw;
    height: 2.77vw;
    margin: 2.6vw 0 0 0.26vw;
  }

  .text_22 {
    width: 3.75vw;
    height: 1.05vw;
    overflow-wrap: break-word;
    color: rgba(156, 156, 156, 1);
    font-size: 0.72vw;
    letter-spacing: 0.3499999940395355px;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 1.05vw;
  }

  .text_23 {
    width: 8.86vw;
    height: 1.41vw;
    overflow-wrap: break-word;
    color: rgba(81, 140, 255, 1);
    font-size: 1.14vw;
    letter-spacing: 0.550000011920929px;
    font-family: Montserrat-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 1.41vw;
    margin-top: 0.32vw;
  }

  .block_51 {
    width: 16.83vw;
    height: 6.36vw;
    margin: 0.93vw 0 0 3.07vw;
  }

  .text_24 {
    width: 3.44vw;
    height: 1.1vw;
    overflow-wrap: break-word;
    color: rgba(211, 211, 211, 1);
    font-size: 0.83vw;
    letter-spacing: 0.4000000059604645px;
    font-family: OPPOSans-R;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 1.1vw;
    margin-left: 0.06vw;
  }

  .text-group_53 {
    width: 16.83vw;
    height: 2.66vw;
    margin-top: 2.61vw;
  }

  .text_25 {
    width: 3.03vw;
    height: 1.05vw;
    overflow-wrap: break-word;
    color: rgba(156, 156, 156, 1);
    font-size: 0.72vw;
    letter-spacing: 0.3499999940395355px;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 1.05vw;
  }

  .text_26 {
    width: 16.83vw;
    height: 1.31vw;
    overflow-wrap: break-word;
    color: rgba(81, 140, 255, 1);
    font-size: 0.93vw;
    letter-spacing: 0.44999998807907104px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 1.31vw;
    margin-top: 0.32vw;
  }

  .image-wrapper_1 {
    height: 7.4vw;
    border: 1px solid rgba(147, 147, 147, 1);
    margin-left: 11.98vw;
    width: 7.4vw;
  }

  .image_3 {
    width: 6.31vw;
    height: 6.31vw;
    margin: 0.57vw 0 0 0.57vw;
  }

  .box_2 {
    background-color: rgba(52, 52, 52, 1);
    width: 75vw;
    height: 0.06vw;
    margin: 1.6vw 0 0 12.5vw;
  }

  .text-wrapper_48 {
    width: 74.02vw;
    height: 1.05vw;
    margin: 1.66vw 0 1.71vw 12.5vw;
  }

  .text_27 {
    width: 29.43vw;
    height: 1.05vw;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 0.72vw;
    letter-spacing: 0.3499999940395355px;
    text-transform: uppercase;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 1.05vw;
  }

  .text_28 {
    width: 6.98vw;
    height: 1.05vw;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 0.72vw;
    letter-spacing: 0.3499999940395355px;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 1.05vw;
  }
}

.footer_nav {
  width: 90%;
  height: 110px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  justify-content: space-between;
  .img_cls {
    width: 254px;
    height: 75px;
    margin-top: -5px;
    cursor: pointer;
    // width: 360px;
    // height: 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .nav_box {
    display: flex;
    width: 1107px;
    align-items: center;
    justify-content: space-around;
    .nav_title {
      font-size: 16px;
      color: #fff;
      flex: 1;
      cursor: pointer;
    }
    .nav_title:hover {
      color: #5299eb;
    }
  }
}

.email_cls {
  margin-left: 12.5vw;
  margin-top: 1vw;
}
</style>
