var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"box_4 flex-col"},[_vm._m(0),_c('div',{staticClass:"text-wrapper_2 flex-col",on:{"click":_vm.goH5Contact}},[_c('span',{staticClass:"text_16"},[_vm._v("渠道合作")])]),_c('div',{staticClass:"text-wrapper_3 flex-col",on:{"click":_vm.sendMessage}},[_c('span',{staticClass:"text_17"},[_vm._v("预约体验")])])]),_c('div',{staticClass:"box_5 flex-col"},[_c('div',{staticClass:"text-wrapper_10 flex-row justify-between"},[_c('span',{staticClass:"text_18",on:{"click":_vm.goProductCenter}},[_vm._v("产品中心")]),_c('span',{staticClass:"text_19",on:{"click":function($event){return _vm.goServiceCase('all')}}},[_vm._v("标杆案例")]),_c('span',{staticClass:"text_20",on:{"click":_vm.goH5Contact}},[_vm._v("渠道合作")]),_c('span',{staticClass:"text_21",on:{"click":_vm.goaboutus}},[_vm._v("关于我们")])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"block_1 flex-col"}),_vm._m(6),_c('div',{staticClass:"text-group_25 flex-col"},[_c('span',{staticClass:"paragraph_1"},[_vm._v(" Copyright @  "+_vm._s(new Date().getFullYear())+". All rights reserved.  "),_c('br'),_vm._v(" 版权所有. 上海扑沃智能科技有限公司 ")]),_c('span',{staticClass:"text_33"},[_vm._v("沪ICP备18037633号")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_20 flex-col"},[_c('span',{staticClass:"text_14"},[_vm._v("开启建筑数字化孪生")]),_c('span',{staticClass:"text_15"},[_vm._v(" 提供全面的电气相关服务与支持，包括设备安装、维护，故障排除，电路设计和优化。 提供专业团队将确保您的电气系统始终高效运行，并提供快速响应和可靠解决方案，以满足您的需求。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_21 flex-col"},[_c('span',{staticClass:"text_25"},[_vm._v("联系电话")]),_c('span',{staticClass:"text_26"},[_vm._v("021-3988 2683")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_22 flex-col"},[_c('span',{staticClass:"text_27"},[_vm._v("全国热线")]),_c('span',{staticClass:"text_28"},[_vm._v("177 2132 3870")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_23 flex-col"},[_c('span',{staticClass:"text_29"},[_vm._v("江浙沪热线")]),_c('span',{staticClass:"text_30"},[_vm._v("139 1881 2305")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_23 flex-col"},[_c('span',{staticClass:"text_29"},[_vm._v("投递邮箱")]),_c('span',{staticClass:"text_30"},[_vm._v("puwo888@163.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_24 flex-col"},[_c('span',{staticClass:"text_31"},[_vm._v("公司地址")]),_c('span',{staticClass:"text_32"},[_vm._v("上海市奉贤区浦星公路9688弄6号6幢二层")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-wrapper_2 flex-col"},[_c('img',{staticClass:"image_6",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/ewm.png")}})])
}]

export { render, staticRenderFns }