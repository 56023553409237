var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"support_box",attrs:{"id":"support_box"}},[_c('div',{staticClass:"support_body"},[_c('div',{staticClass:"support_msg"},[_c('div',{staticClass:"support_name"},[_vm._v("服务与支持")]),_c('div',{staticClass:"support_des"},[_vm._v(" 提供全面的电气相关服务与支持，包括设备安装、维护，故障排除，电路设计和优化。 提供专业团队将确保您的电气系统始终高效运行，并提供快速响应和可靠解决方案，以满足您的需求。 ")]),_c('div',{staticClass:"support_iconfun"},[_c('el-tooltip',{attrs:{"placement":"top"}},[_c('div',{staticStyle:{"line-height":"2"},attrs:{"slot":"content"},slot:"content"},[_vm._v(" 全国热线：17721323870"),_c('br'),_vm._v(" 固话：021-39882683"),_c('br'),_vm._v(" 邮箱：puwo888@163.com ")]),_c('div',{staticClass:"iconfun_item"},[_c('div',{staticClass:"icon_cls"},[_c('img',{attrs:{"src":require("../../assets/image/support1.png"),"alt":""}})]),_c('div',{staticClass:"fun_name"},[_vm._v("联系方式")])])]),_c('div',{staticClass:"iconfun_item",on:{"click":() => {
              this.$router.push('/service-case');
            }}},[_vm._m(0),_c('div',{staticClass:"fun_name"},[_vm._v("服务案例")])]),_c('div',{staticClass:"iconfun_item",on:{"click":() => {
              this.$router.push('/cooperation');
            }}},[_vm._m(1),_c('div',{staticClass:"fun_name"},[_vm._v("渠道合作")])])],1)]),_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon_cls"},[_c('img',{attrs:{"src":require("../../assets/image/support2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon_cls"},[_c('img',{attrs:{"src":require("../../assets/image/support3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_cls"},[_c('img',{attrs:{"src":require("../../assets/image/support.png"),"alt":""}})])
}]

export { render, staticRenderFns }