<template>
  <div class="aboutus_box" id="aboutus">
    <div class="block_18 flex-col">
      <div class="group_40 flex-row">
        <div class="block_19 flex-col"></div>
        <div class="text-wrapper_52 flex-col justify-between">
          <span class="text_51">上海扑沃智能科技有限公司</span>
          <span class="text_52">COMPANY&nbsp;PROFILE</span>
        </div>
      </div>
      <div class="company_box_cls">
        <div class="company_box_left">
          <div class="group_41 flex-row">
            <div class="text-group_55 flex-col justify-between">
              <span class="text_53"
                >敢为人先，追求卓越，以创新为动力，以质量谋发展</span
              >
              <span class="paragraph_2">
                扑沃科技争做中国能源管理系统领先品牌，&nbsp;敢为人先，追求卓越，以创新为动力，以质量谋发展，服务“碳达峰、碳中和”战略决策，&nbsp;共筑中国梦-绿色化，扑沃将用科技的力量，还原自然的生态环境。
              </span>
              <span class="paragraph_2">
                作为公共建筑能耗监测系统领域的高新技术企业，扑沃科技致力于提供能耗监测系统解决方案、相关产品及技术服务。&nbsp;公司具备专业的技术团队及多年的项目经验，具有强大的研发生产、销售及系统集成能力。&nbsp;产品涵盖了PWT-EMS系列能耗监测系统软件、PWT-DG系列物联网关(数据采集设备)，以及各类智能电力仪表、传感器。
              </span>
            </div>
          </div>
          <div class="group_42 flex-row justify-between">
            <div class="text-wrapper_17 flex-col" @click="goCooperation">
              <span class="text_65">联系我们</span>
            </div>
            <div class="group_17 flex-row" @click="goaboutus">
              <span class="text_66">READ&nbsp;MORE</span>
              <img
                class="image_8"
                referrerpolicy="no-referrer"
                src="../../assets/index/right_blue.png"
              />
            </div>
          </div>
        </div>
        <div class="company_box_right">
          <img
            class="image_28"
            referrerpolicy="no-referrer"
            src="../../assets/index/company.png"
          />
        </div>
      </div>
      <!-- <div class="group_43 flex-row">
        <div class="group_16 flex-col">
          <div class="block_20 flex-col"></div>
        </div>
        <div class="box_40 flex-row">
          <div class="text-group_56 flex-col justify-between">
            <span class="text_56">2015</span>
            <span class="text_55">公司成立</span>
          </div>
          <span class="text_57">年</span>
          <div class="group_12 flex-col">
            <div class="group_13 flex-col"></div>
          </div>
        </div>
        <div class="box_41 flex-row">
          <div class="text-group_57 flex-col justify-between">
            <span class="text_59">1000</span>
            <span class="text_58">注册资金</span>
          </div>
          <span class="text_60">万元</span>
          <div class="group_14 flex-col">
            <div class="section_17 flex-col"></div>
          </div>
        </div>
        <div class="box_42 flex-row">
          <span class="text_62">500+</span>
          <div class="group_15 flex-col">
            <div class="box_13 flex-col"></div>
          </div>
          <div class="text-wrapper_53 flex-col justify-between">
            <span class="text_64">100+</span>
            <span class="text_63">资质证书</span>
          </div>
        </div>
        <img
          class="image_28"
          referrerpolicy="no-referrer"
          src="../../assets/index/company.png"
        />
        <span class="text_61">成功案例</span>
      </div> -->
    </div>
    <div class="box_47 flex-row justify-between">
      <div class="block_32 flex-col"></div>
      <div class="text-wrapper_58 flex-col justify-between">
        <span class="text_99">资质证书</span>
        <span class="text_100">certificate</span>
      </div>
    </div>
    <div class="about_lun_box">
      <div class="about_lun">
        <ul id="lun_box">
          <li class="lun_box_item">
            <img src="../../assets/image/zs/DDS1852_00.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/DDS1852Y_00.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/DDS1852Y检测报告.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img
              src="../../assets/image/zs/DTS1852、PWT803.830检测报告.jpg"
              alt=""
            />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/DTS1852_00.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/DTS1852Y_00.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img
              src="../../assets/image/zs/ISO质量管理体系认证证书.jpg"
              alt=""
            />
          </li>
          <!-- <li class="lun_box_item">
            <img src="../../assets/image/zs/PWD800_00.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/PWD800Y_00.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/PWT810A_00.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/PWT810V_00.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/PWT830_00.jpg" alt="" />
          </li> 
          <li class="lun_box_item">
            <img src="../../assets/image/zs/PWT830A_00.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/PWT830V_00.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/PWT950_00.jpg" alt="" />
          </li>-->
          <li class="lun_box_item">
            <img src="../../assets/image/zs/高企业证书.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/软著2023-1.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/软著2023-2.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/软著2023-3.jpg" alt="" />
          </li>
          <!-- <li class="lun_box_item">
            <img
              src="../../assets/image/zs/rz/扑沃PWT-CMS集控运维管理软件V2.0.jpg"
              alt=""
            />
          </li> -->
          <li class="lun_box_item">
            <img src="../../assets/image/zs/网关检测报告.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/网关检测报告-2.jpg" alt="" />
          </li>
          <li class="lun_box_item">
            <img src="../../assets/image/zs/营业执照.jpg" alt="" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
var timer = null;
export default {
  data() {
    return {
      timer: null,
    };
  },
  mounted() {
    this.initLun();
  },
  methods: {
    goCooperation() {
      this.$router.push({
        name: "Cooperation",
      });
    },
    goaboutus() {
      this.$router.push({
        name: "CompanyIntro",
      });
    },
    goId(val) {
      document.querySelector("#" + val).scrollIntoView(true);
    },
    initLun() {
      let ul = document.querySelector("#lun_box");
      ul.innerHTML = ul.innerHTML + ul.innerHTML;
      let lis = document.querySelectorAll(".lun_box_item");
      // 每次滚动的跨度
      let spa = -1;
      ul.style.width = (lis[0].offsetWidth + 20) * lis.length + "px";
      let move = () => {
        if (ul.offsetLeft < -ul.offsetWidth / 2) {
          ul.style.left = "0";
        }
        ul.style.left = ul.offsetLeft + spa + "px";
        this.timer = requestAnimationFrame(move);
      };
      this.timer = requestAnimationFrame(move);
      ul.addEventListener("mouseout", () => {
        this.timer = requestAnimationFrame(move);
      });
      ul.addEventListener("mouseover", () => {
        cancelAnimationFrame(this.timer);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.aboutus_box {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  // background-color: #fff;
  // background-image: url("../../assets/index/bg3.png");
  background-image: linear-gradient(#fff, #f8faff);
}
.gywmhz {
  text-align: center;
  font-size: 60px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2b2b2b;
  line-height: 23px;
  margin: 128px auto 65px auto;
  z-index: 999;
}
.gywm {
  position: absolute;
  top: 102px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  font-family: Helvetica;
  font-weight: bold;
  color: #cccccc;
  line-height: 23px;
  opacity: 0.6;
  z-index: 1;
}

.about_body {
  //   width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  .img_cls {
    width: 600px;
    height: 360px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .company_msg {
    width: 600px;
    height: 360px;
    background-color: #fff;
    padding-top: 35px;
    padding-left: 60px;
    box-sizing: border-box;
    .company_name {
      font-size: 20px;
      line-height: 40px;
      text-align: left;
      color: #333333;
      font-weight: bold;
      font-family: Microsoft YaHei;
      height: 40px;
    }
    .company_des {
      text-indent: 2em;
      width: 480px;
      height: 90px;
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      color: #666666;
      font-weight: normal;
      font-family: Microsoft YaHei;
      font-style: normal;
      margin-top: 15px;
    }
    .company_iconfun {
      display: flex;
      margin-top: 36px;
      width: 480px;
      justify-content: space-around;
      .iconfun_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .icon_cls {
          width: 44px;
          height: 44px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .fun_name {
          margin-top: 0px;
          padding-top: 9px;
          font-size: 14px;
          color: #666666;
          height: 40px;
          line-height: 40px;
          font-family: Microsoft YaHei;
          background: transparent;
        }
      }
    }
  }
}
.about_lun_box {
  width: 100vw;
  height: 260px;
  margin: 2vw auto;
  // background-image: url("../../assets/index/bg3.png");
}
.about_lun {
  width: 75vw;
  height: 250px;
  margin: 20px auto;
  display: flex;
  position: relative;
  overflow: hidden;
  // box-shadow: 0 0.2vw 0.2vw 0 rgba(0, 0, 0, 0.5);
  ul {
    width: 1200px;
    height: 250px;
    overflow: hidden;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;

    li {
      list-style: none;
      height: 100%;
      cursor: pointer;
      margin-right: 20px;
      img {
        width: 165px;
        height: 234px;
        object-fit: cover;
        object-position: center;
        box-shadow: 0.2vw 0.2vw 0.2vw 0 rgba(0, 0, 0, 0.3);
      }
    }
  }
}
// 新版样式css
.block_18 {
  // background-color: rgba(255, 255, 255, 1);
  width: 100vw;
  margin-bottom: 3vw;
}

.group_40 {
  width: 75vw;
  height: 3.91vw;
  margin: 3.69vw auto 0;
}

.block_19 {
  background-color: rgba(52, 57, 192, 1);
  width: 0.21vw;
  height: 3.44vw;
  margin-top: 0.16vw;
}

.text-wrapper_52 {
  height: 3.91vw;
  margin-left: 0.9vw;
}

.text_51 {
  width: 15.42vw;
  height: 1.72vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 1.25vw;
  letter-spacing: 0.6000000238418579px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 1.72vw;
}

.text_52 {
  width: 17.35vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 1.77vw;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 2.19vw;
}

.company_box_cls {
  width: 87.5vw;
  margin-left: 12.5vw;
  display: flex;
  .company_box_right {
    margin-top: -4.6vw;
  }
}

.group_41 {
  width: 44vw;
  margin: 2.08vw 0 0 0;
}

.text-group_55 {
  width: 44vw;
}

.text_53 {
  width: 24.59vw;
  overflow-wrap: break-word;
  color: rgba(52, 57, 192, 1);
  font-size: 1.04vw;
  letter-spacing: 0.5px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 1.46vw;
}

.paragraph_2 {
  width: 43.23vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.83vw;
  font-weight: NaN;
  text-align: left;
  line-height: 1.65vw;
  margin-top: 0.63vw;
  letter-spacing: 0.2vw;
  text-indent: 2em;
}

.group_42 {
  width: 17.61vw;
  height: 1.88vw;
  margin-top: 3vw;
}

.text-wrapper_17 {
  background-color: rgba(52, 57, 192, 1);
  border-radius: 18px;
  height: 1.88vw;
  width: 7.19vw;
  cursor: pointer;
}

.text_65 {
  width: 2.92vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.72vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  margin: 0.41vw 0 0 2.13vw;
}

.group_17 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 18px;
  width: 8.86vw;
  height: 1.88vw;
  border: 1px solid rgba(52, 57, 192, 1);
  cursor: pointer;
}

.text_66 {
  width: 4.38vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(52, 57, 192, 1);
  font-size: 0.72vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  margin: 0.41vw 0 0 0.98vw;
}

.image_8 {
  width: 1.15vw;
  height: 0.32vw;
  margin: 0.72vw 1.19vw 0 1.14vw;
}

.group_43 {
  position: relative;
  width: 61.98vw;
  height: 13.34vw;
  margin: 0.78vw 0 0.88vw 12.5vw;
}

.group_16 {
  background-color: rgba(248, 248, 248, 1);
  height: 3.6vw;
  margin-top: 3.81vw;
  width: 0.06vw;
}

.block_20 {
  background-color: rgba(216, 216, 216, 1);
  width: 0.11vw;
  height: 1.78vw;
}

.box_40 {
  width: 11.25vw;
  height: 13.08vw;
  background-size: 100% 100%;
  margin: 0.1vw 0 0 1.04vw;
}

.text-group_56 {
  width: 5.68vw;
  height: 3.96vw;
  margin: 3.12vw 0 0 0.62vw;
}

.text_56 {
  width: 5.68vw;
  height: 2.82vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 2.29vw;
  font-family: Montserrat-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 2.82vw;
}

.text_55 {
  width: 3.34vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.83vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
}

.text_57 {
  width: 0.94vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.93vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 4.16vw 0 0 0.36vw;
}

.group_12 {
  background-color: rgba(248, 248, 248, 1);
  height: 3.6vw;
  width: 0.06vw;
  margin: 3.69vw 0.57vw 0 3.02vw;
}

.group_13 {
  background-color: rgba(216, 216, 216, 1);
  width: 0.11vw;
  height: 1.78vw;
}

.box_41 {
  width: 12.3vw;
  height: 13.23vw;
  background-size: 12.34vw 13.28vw;
  margin: 0.1vw 0 0 1.3vw;
}

.text-group_57 {
  width: 5.68vw;
  height: 3.96vw;
  margin: 3.12vw 0 0 -0.2vw;
}

.text_59 {
  width: 5.68vw;
  height: 2.82vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 2.29vw;
  font-family: Montserrat-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 2.82vw;
}

.text_58 {
  width: 3.34vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.83vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
}

.text_60 {
  width: 1.88vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.93vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 4.16vw 0 0 0.36vw;
}

.group_14 {
  background-color: rgba(248, 248, 248, 1);
  height: 3.6vw;
  width: 0.06vw;
  margin: 3.69vw 1.51vw 0 3.02vw;
}

.section_17 {
  background-color: rgba(216, 216, 216, 1);
  width: 0.11vw;
  height: 1.78vw;
}

.box_42 {
  width: 20.73vw;
  height: 13.08vw;
  background-size: 20.78vw 13.07vw;
  margin: 0.1vw 0 0 0.83vw;
}

.text_62 {
  width: 5.42vw;
  height: 2.82vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 2.29vw;
  font-family: Montserrat-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 2.82vw;
  margin: 3.12vw 0 0 -0.67vw;
}

.group_15 {
  background-color: rgba(248, 248, 248, 1);
  height: 3.6vw;
  width: 0.06vw;
  margin: 3.69vw 0 0 3.02vw;
}

.box_13 {
  background-color: rgba(216, 216, 216, 1);
  width: 0.11vw;
  height: 1.78vw;
}

.text-wrapper_53 {
  width: 5.42vw;
  height: 3.96vw;
  margin: 3.12vw 5.83vw 0 1.66vw;
}

.text_64 {
  width: 5.42vw;
  height: 2.82vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 2.29vw;
  font-family: Montserrat-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 2.82vw;
}

.text_63 {
  width: 3.34vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.83vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
}

.image_28 {
  width: 43vw;
  height: 30vw;
}

.text_61 {
  position: absolute;
  left: 26.1vw;
  top: 6.05vw;
  width: 3.34vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.83vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
}

// 证书css
.box_47 {
  width: 12.56vw;
  margin-left: 12.5vw;
}

.block_32 {
  background-color: rgba(52, 57, 192, 1);
  width: 0.21vw;
  height: 3.44vw;
  margin-top: 0.16vw;
}

.text-wrapper_58 {
  width: 11.41vw;
  height: 3.91vw;
}

.text_99 {
  width: 5.16vw;
  height: 1.72vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 1.25vw;
  letter-spacing: 0.6000000238418579px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 1.72vw;
}

.text_100 {
  width: 11.41vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 1.77vw;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 2.19vw;
}
</style>