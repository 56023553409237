var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"block_21 flex-row"},[_vm._m(0),_c('div',{staticClass:"text-wrapper_18 flex-col",on:{"click":_vm.goCooperation}},[_c('span',{staticClass:"text_69"},[_vm._v("渠道合作")])]),_c('div',{staticClass:"text-wrapper_19 flex-col",on:{"click":_vm.sendMessage}},[_c('span',{staticClass:"text_70"},[_vm._v("立即体验")])])]),_c('div',{staticClass:"block_2 flex-col"},[_c('div',{staticClass:"box_30 flex-row"},[_c('div',{staticClass:"block_48 flex-col justify-between"},[_c('div',{staticClass:"text-wrapper_45 flex-row justify-between"},[_c('span',{staticClass:"text_12",on:{"click":function($event){return _vm.goProductCenter('nywlwyjcp', 'yjall')}}},[_vm._v("产品中心")]),_c('span',{staticClass:"text_13",on:{"click":function($event){return _vm.goProductCenter('nyszhjjfa', 'rjall')}}},[_vm._v("解决方案")])]),_vm._m(1)]),_c('div',{staticClass:"block_49 flex-col justify-between"},[_c('div',{staticClass:"text-wrapper_46 flex-row justify-between"},[_c('span',{staticClass:"text_13",on:{"click":_vm.goServiceCase}},[_vm._v("标杆案例")]),_c('span',{staticClass:"text_16",on:{"click":_vm.goCooperation}},[_vm._v("渠道合作")])]),_vm._m(2)]),_c('div',{staticClass:"block_50 flex-col justify-between"},[_c('div',{staticClass:"text-wrapper_47 flex-row justify-between"},[_c('span',{staticClass:"text_17",on:{"click":_vm.goComPanyIntro}},[_vm._v("关于我们")])]),_vm._m(3)]),_vm._m(4),_vm._m(5)]),_vm._m(6),_c('div',{staticClass:"box_2 flex-col"}),_c('div',{staticClass:"text-wrapper_48 flex-row justify-between"},[_c('span',{staticClass:"text_27"},[_vm._v(" Copyright @  "+_vm._s(new Date().getFullYear())+". All rights reserved. 版权所有. 上海扑沃智能科技有限公司 ")]),_c('span',{staticClass:"text_28"},[_vm._v("沪ICP备18037633号")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_58 flex-col justify-between"},[_c('span',{staticClass:"text_67"},[_vm._v("开启建筑数字化孪生")]),_c('span',{staticClass:"text_68"},[_vm._v(" 提供全面的能源相关服务与支持，包括设备安装、维护，故障排除，电路设计和优化。 提供专业团队将确保您的能源系统始终高效运行，并提供快速响应和可靠解决方案，以满足您的需求。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_50 flex-col justify-between"},[_c('span',{staticClass:"text_14"},[_vm._v("联系电话")]),_c('span',{staticClass:"text_15"},[_vm._v("021-3988 2683")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_51 flex-col justify-between"},[_c('span',{staticClass:"text_18"},[_vm._v("全国热线")]),_c('span',{staticClass:"text_19"},[_vm._v("177 2132 3870")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_52 flex-col justify-between"},[_c('span',{staticClass:"text_22"},[_vm._v("江浙沪热线")]),_c('span',{staticClass:"text_23"},[_vm._v("139 1881 2305")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block_51 flex-col justify-between"},[_c('span',{staticClass:"text_24"}),_c('div',{staticClass:"text-group_53 flex-col justify-between"},[_c('span',{staticClass:"text_25"},[_vm._v("公司地址")]),_c('span',{staticClass:"text_26"},[_vm._v("上海市奉贤区浦星公路9688弄6号6幢二层")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-wrapper_1 flex-col"},[_c('img',{staticClass:"image_3",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/footter/ewm.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"email_cls flex-col justify-between"},[_c('span',{staticClass:"text_14"},[_vm._v("投递邮箱")]),_c('span',{staticClass:"text_15"},[_vm._v("puwo888@163.com")])])
}]

export { render, staticRenderFns }