<template>
  <div class="header_h5">
    <div
      class="header_box flex-row justify-between"
      :style="{ 'background-color': isTransparent ? 'transparent' : '#fff' }"
    >
      <div @click="goHomePage">
        <div class="image-wrapper_3 flex-col" v-if="!isTransparent">
          <img
            class="label_2"
            referrerpolicy="no-referrer"
            src="./assets/img/logo_en.png"
          />
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/logo_cn.png"
          />
        </div>
        <div class="image-wrapper_3 flex-col" v-if="isTransparent">
          <img
            class="label_2"
            referrerpolicy="no-referrer"
            src="./assets/img/logo_en_t.png"
          />
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/logo_cn_t.png"
          />
        </div>
      </div>
      <img
        class="image_8"
        referrerpolicy="no-referrer"
        :src="isShowMenu ? closeimg : moreimg"
        @click="changeMenuStatus"
      />
    </div>
    <!-- 菜单盒子 -->
    <div class="menu_box" v-show="isShowMenu">
      <div class="menu_item_box">
        <div
          @click="goShowPage(item.value)"
          class="menu_item"
          v-for="(item, index) in menuList"
          :key="index"
        >
          <div class="father_box" @click="changeExpandStatus(index)">
            {{ item.label }}
            <img
              class="arrow_cls"
              :class="item.isExpand ? 'down_arrow_cls' : ''"
              v-show="item.children"
              src="./assets/img/right.png"
              alt=""
            />
          </div>
          <div class="children_box" v-show="item.isExpand">
            <div
              class="children_item"
              v-for="(child, idx) in item.children"
              :key="idx"
              @click.stop="goShowPage(child.value)"
            >
              <div style="display: flex; align-items: center">
                <img
                  style="width: 24px; height: 24px; margin-right: 2vw"
                  :src="child.icon"
                  alt=""
                />
                {{ child.label }}
              </div>
              <img
                class="arrow_cls"
                src="./assets/img/right_orange.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="block_2 flex-col">
        <div class="box_7 flex-row">
          <div class="image-text_9 flex-row justify-between">
            <img
              class="thumbnail_17"
              referrerpolicy="no-referrer"
              src="./assets/img/phone.png"
            />
            <span class="text-group_7">021-39882683</span>
          </div>
        </div>
        <div class="text-wrapper_1 flex-col" @click="changeExperStatus">
          <span class="text_1">立即体验</span>
        </div>
      </div>
    </div>
    <!-- 体验盒子 -->
    <div class="exper_box">
      <div class="title_box">
        <div class="block_24 flex-row justify-between">
          <span class="text_33">预约体验</span>
        </div>
        <div class="text_34">请提交需求，我们马上跟您取得联系：</div>
      </div>
      <div class="form_box">
        <el-form
          :model="formdata"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <div class="formbox_line1">
            <el-form-item prop="name" class="line1_item">
              <div class="form_label">姓名</div>
              <el-input
                v-model="formdata.name"
                placeholder="请输入您的姓名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="companyname" class="line1_item">
              <div class="form_label">公司名称</div>
              <el-input
                v-model="formdata.companyname"
                placeholder="请输入您的姓名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="phone" class="line1_item">
              <div class="form_label">手机号</div>
              <el-input
                v-model="formdata.phone"
                placeholder="请输入您的手机号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="email" class="line1_item">
              <div class="form_label">邮箱</div>
              <el-input
                v-model="formdata.email"
                placeholder="请输入您的邮箱"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item prop="profession">
            <div class="form_label">公司所在行业</div>
            <el-input
              v-model="formdata.profession"
              placeholder="请输入公司所在行业"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <div class="form_label">
              如果您有其他想了解的信息或者疑问，欢迎给我们留言（选填）
            </div>
            <el-input
              v-model="formdata.remark"
              type="textarea"
              :rows="4"
              placeholder="请填写您的内容"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button @click="sumbit" style="float: left">立即预约</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import moreimg from "./assets/img/more.png";
import moreimg_t from "./assets/img/more_t.png";
import closeimg from "./assets/img/close.png";
// 云平台图标
import dlywypt from "./assets/img/dlywypt.png";
import sxxglpt from "./assets/img/sxxglpt.png";
import yffypt from "./assets/img/yffypt.png";
import zhnhypt from "./assets/img/zhnhypt.png";
import kqzljcpt from "./assets/img/kqzljcpt.png";
import gfywypt from "./assets/img/gfywypt.png";
import ycfkypt from "./assets/img/ycfkypt.png";
// 案例图标
import syzht from "./assets/img/syzht.png";
import yljy from "./assets/img/yljy.png";
import zhyq from "./assets/img/zhyq.png";
import jdgy from "./assets/img/jdgy.png";
import gkqy from "./assets/img/gkqy.png";
import gfcn from "./assets/img/gfcn.png";
// 产品图标
import nhgl from "./assets/img/nhgl.png";
import dljk from "./assets/img/dljk.png";
import hjjc from "./assets/img/hjjc.png";
import lyjzm from "./assets/img/lyjzm.png";
import dlybxl from "./assets/img/dlybxl.png";
import snkqcgq from "./assets/img/snkqcgq.png";
import txsb from "./assets/img/txsb.png";

import { bus } from "../bus.js";

export default {
  data() {
    return {
      isShowMenu: false,
      isTransparent: false,
      moreimg: moreimg,
      closeimg: closeimg,
      menuList: [
        {
          label: "首页",
          isExpand: false,
          value: "sy",
        },
        {
          label: "产品中心",
          isExpand: true,
          children: [
            {
              label: "能耗管理",
              value: "nhgl",
              icon: nhgl,
            },
            {
              label: "电力监控",
              value: "dljk",
              icon: dljk,
            },
            {
              label: "环境监测",
              value: "hjjc",
              icon: hjjc,
            },
            {
              label: "楼控及照明",
              value: "lkjzm",
              icon: lyjzm,
            },
            {
              label: "电力仪表系列",
              value: "dlyb",
              icon: dlybxl,
            },
            {
              label: "室内空气传感器",
              value: "snkqcgq",
              icon: snkqcgq,
            },
            {
              label: "通讯设备",
              value: "txsb",
              icon: txsb,
            },
          ],
        },
        {
          label: "标杆案例",
          isExpand: false,
          children: [
            {
              label: "商业综合体",
              value: "syzht",
              icon: syzht,
            },
            {
              label: "教育医疗",
              value: "jyyl",
              icon: yljy,
            },
            {
              label: "智慧园区",
              value: "zhyq",
              icon: zhyq,
            },
            {
              label: "酒店公寓",
              value: "jdgy",
              icon: jdgy,
            },
            {
              label: "工矿企业",
              value: "gkqy",
              icon: gkqy,
            },
            {
              label: "光伏储能",
              value: "gfcn",
              icon: gfcn,
            },
          ],
        },
        // {
        //   label: "云平台",
        //   isExpand: false,
        //   children: [
        //     {
        //       label: "电力运维云平台",
        //       value: "dlywypt",
        //       icon: dlywypt,
        //     },
        //     {
        //       label: "水信息管理平台",
        //       value: "sxxglpt",
        //       icon: sxxglpt,
        //     },
        //     {
        //       label: "预付费云平台",
        //       value: "yffypt",
        //       icon: yffypt,
        //     },
        //     {
        //       label: "综合能耗云平台",
        //       value: "zhnhypt",
        //       icon: zhnhypt,
        //     },
        //     {
        //       label: "空气质量监测平台",
        //       value: "kqzljcpt",
        //       icon: kqzljcpt,
        //     },
        //     {
        //       label: "光伏运维云平台",
        //       value: "gfywypt",
        //       icon: gfywypt,
        //     },
        //     {
        //       label: "远程费控云平台",
        //       value: "ycfkypt",
        //       icon: ycfkypt,
        //     },
        //   ],
        // },
        {
          label: "渠道合作",
          isExpand: false,
          value: "qdhz",
        },
        {
          label: "关于我们",
          isExpand: false,
          value: "gywm",
        },
        // {
        //   label: "公司动态",
        //   isExpand: false,
        // },
      ],
      isShowExper: false,
      formdata: {
        email: "",
        name: "",
        phone: "",
        remark: "",
        email: "",
        tel: "",
        companyname: "",
        profession: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        companyname: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        profession: [
          { required: true, message: "请输入公司所在行业", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: "请输入正确的手机号码",
          },
        ],
        email: [{ required: true, message: "请输入您的邮箱", trigger: "blur" }],
        // remark: [{ required: true, message: "请输入留言", trigger: "blur" }],
      },
      submitURL: window.gurl.URL + "customerinfo/submit",
    };
  },
  created() {
    bus.$on("showExperEvent", (data) => {
      this.isTransparent = false;
      this.isShowMenu = true;
      this.changeExperStatus();
    });
  },
  mounted() {},
  destroyed() {},
  methods: {
    goHomePage() {
      this.$router.push({
        name: "Main",
      });
    },
    async sumbit() {
      const res1 = await this.axios.post(this.submitURL, {
        ...this.formdata,
      });
      if (res1.rescode == 200) {
        this.$message.success({
          message: "预约成功，请等待客服联系",
          offset: 120,
        });
      }
    },
    showDrawer() {},
    changeExpandStatus(index) {
      this.menuList[index].isExpand = !this.menuList[index].isExpand;
      if (this.menuList[index].isExpand) {
        this.menuList.map((item, i) => {
          if (i == index) {
            return;
          } else {
            item.isExpand = false;
          }
        });
      }
    },
    changeMenuStatus() {
      this.isShowMenu = !this.isShowMenu;
      let dom = document.getElementsByClassName("menu_box");
      if (this.isShowMenu) {
        dom[0].style.opacity = "1";
        dom[0].style.visibility = "visible";
        dom[0].style.transition = "0.2s";
      } else {
        dom[0].style.opacity = "0";
        dom[0].style.visibility = "hidden";
        dom[0].style.transition = "0.2s";
        this.closeExper();
      }
      this.isTransparent = false;
    },
    changeExperStatus() {
      this.isShowExper = !this.isShowExper;
      let dom1 = document.getElementsByClassName("exper_box");
      if (this.isShowExper) {
        dom1[0].style.opacity = "1";
        dom1[0].style.visibility = "visible";
        dom1[0].style.transition = "0.2s";
      } else {
        dom1[0].style.opacity = "0";
        dom1[0].style.visibility = "hidden";
        dom1[0].style.transition = "0.2s";
      }
      this.isTransparent = false;
    },
    closeExper() {
      this.isShowExper = false;
      let dom = document.getElementsByClassName("exper_box");
      dom[0].style.opacity = "0";
      dom[0].style.visibility = "hidden";
      dom[0].style.transition = "0.2s";
    },
    goShowPage(val) {
      if (val == "qdhz") {
        this.$router.push({ name: "H5Contact" });
        this.changeMenuStatus();
      } else if (val == "gywm") {
        this.$router.push({ name: "H5Aboutus" });
        this.changeMenuStatus();
      } else if (val == "sy") {
        this.$router.push({ name: "Main" });
        this.changeMenuStatus();
      } else if (
        val == "nhgl" ||
        val == "dljk" ||
        val == "hjjc" ||
        val == "lkjzm"
      ) {
        this.$router.push({
          name: "H5Product",
          params: {
            curFatherType: "nyszhjjfa",
            curSonType: "rj" + val,
          },
        });
        this.changeMenuStatus();
      } else if (val == "dlyb" || val == "snkqcgq" || val == "txsb") {
        this.$router.push({
          name: "H5Product",
          params: {
            curFatherType: "nywlwyjcp",
            curSonType: "yj" + val,
          },
        });
        this.changeMenuStatus();
      } else if (val == "dlywypt") {
        window.open("http://power.puwo-tech.com/", "_blank");
      } else if (val == "sxxglpt") {
        window.open("http://water.puwo-tech.com/", "_blank");
      } else if (val == "yffypt") {
        window.open("http://prepay.puwo-tech.com/", "_blank");
      } else if (val == "zhnhypt") {
        window.open("http://energy.puwo-tech.com/", "_blank");
      } else if (val == "kqzljcpt") {
        window.open("http://aqms.puwo-tech.com/", "_blank");
      } else if (val == "gfywypt") {
        window.open("http://pv.puwo-tech.com/", "_blank");
      } else if (val == "ycfkypt") {
        window.open("http://feecontrol.puwo-tech.com/", "_blank");
      } else if (
        val == "syzht" ||
        val == "jyyl" ||
        val == "zhyq" ||
        val == "jdgy" ||
        val == "gkqy" ||
        val == "gfcn"
      ) {
        this.$router.push({
          name: "H5ServiceCase",
          params: {
            caseType: val,
          },
        });
        this.changeMenuStatus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header_box {
  z-index: 9999;
  background-color: transparent;
  width: 100vw;
  padding: 3.2vw 4vw 3.73vw 4vw;
  position: fixed;
  top: 0;
  left: 0;
  .label_2 {
    width: 12.8vw;
    height: 5.87vw;
  }

  .image_7 {
    width: 10.94vw;
    height: 2.67vw;
    margin: 0 0.53vw 0 1.33vw;
  }

  .image_8 {
    width: 5.6vw;
    height: 4.8vw;
    margin: 1.86vw 0 1.86vw 0;
  }
}
.menu_box {
  opacity: 0;
  visibility: hidden;
  height: calc(100vh - 15.5vw);
  background-color: #fff;
  position: fixed;
  top: 15.5vw;
  z-index: 999;

  .menu_item_box {
    height: calc(100vh - 63vw);
    margin: 3vw 0;
    overflow-y: auto;
    overflow-x: hidden;
    .father_box {
      width: 100vw;
      height: 48px;
      line-height: 48px;
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #222222;
      padding: 0 4vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .arrow_cls {
      width: 10px;
      height: 17px;
      transition: all 0.2s;
    }
    .down_arrow_cls {
      transform: rotate(90deg);
      transition: all 0.2s;
    }
    .children_box {
      width: 100vw;
      .children_item {
        width: 92vw;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
        font-family: OPPOSans, OPPOSans;
        font-weight: 500;
        color: #222222;
        padding: 0 4vw;
        background: #fff7f2;
        border-radius: 6px;
        margin: 1.34vw auto 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  // 电话和体验相关css
  .block_2 {
    background-color: rgba(255, 255, 255, 1);
    padding: 7.73vw 5.33vw 6.13vw 5.6vw;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .box_7 {
    background-color: rgba(249, 249, 255, 1);
    border-radius: 22px;
    border: 1px solid rgba(52, 57, 192, 1);
    padding: 1.86vw 24vw 2.66vw 24vw;
  }

  .image-text_9 {
    width: 40.54vw;
  }

  .thumbnail_17 {
    width: 5.34vw;
    height: 5.34vw;
    margin: 1.06vw 0 0.26vw 0;
  }

  .text-group_7 {
    overflow-wrap: break-word;
    color: rgba(52, 57, 192, 1);
    font-size: 4.26vw;
    font-family: OPPOSans-H;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 6.67vw;
  }

  .text-wrapper_1 {
    box-shadow: 0px 5px 7px 0px rgba(9, 14, 145, 0.15);
    background-color: rgba(52, 57, 192, 1);
    border-radius: 22px;
    margin-top: 5.34vw;
    padding: 2.93vw 35.73vw 2.93vw 35.73vw;
  }

  .text_1 {
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 4.26vw;
    letter-spacing: 0.4000000059604645px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 5.87vw;
  }
}
.exper_box {
  opacity: 0;
  visibility: hidden;
  width: 100vw;
  height: calc(100vh - 15.5vw);
  background-color: #fff;
  position: fixed;
  top: 15.5vw;
  z-index: 1111;
  padding: 8.8vw 3.86vw;

  .title_box {
    margin-bottom: 3vw;
    .text_33 {
      overflow-wrap: break-word;
      color: rgba(52, 57, 192, 1);
      font-size: 5.33vw;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      line-height: 7.47vw;
    }

    .thumbnail_8 {
      width: 0.94vw;
      height: 0.94vw;
      margin-top: 0.32vw;
    }

    .text_34 {
      margin-top: 0.5vw;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 3.73vw;
      font-weight: NaN;
      text-align: left;
      white-space: nowrap;
      line-height: 5.34vw;
    }
  }

  .form_box {
    margin-top: 1vw;
    .formbox_line1 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .line1_item {
        width: 100%;
      }
    }
    .form_label {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      text-align: left;
      margin-bottom: 0.3vw;
    }
  }
}

.el-button {
  width: 200px;
  height: 50px;
  color: #fff;
  background: #3439c0;
  border-radius: 0px;
}
::v-deep .el-input__inner {
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  background-color: #f3f4f5;
}
::v-deep .el-textarea__inner {
  background-color: #f3f4f5;
}

::v-deep .el-select {
  width: 260px;
  height: 50px;
  .el-input__inner {
    border-radius: 0;
    height: 50px;
    line-height: 50px;
  }
  .el-input__inner:focus {
    border-color: transparent;
  }
}
</style>