import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

//Axios
import axios from 'axios'
// axios.defaults.baseURL = 'http://www.baiduansat.com'

// axios.interceptors.request.use(
//   config => {
//     config.headers['token'] = 'www.baiduansat.com'
//     return config
//   },
//   err => {
//     return Promise.reject(err)
//   }
// )

axios.interceptors.response.use(
  (response) => {
    const res = response.data;
    return Promise.resolve(res);
  },
  (error) => {
    return Promise.reject(error);
  },
);


Vue.prototype.axios = axios

//QS(QueryString)
import qs from 'qs'
Vue.prototype.qs = qs

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
