<template>
  <div>
    <div class="box_4 flex-col">
      <div class="text-group_20 flex-col">
        <span class="text_14">开启建筑数字化孪生</span>
        <span class="text_15">
          提供全面的电气相关服务与支持，包括设备安装、维护，故障排除，电路设计和优化。&nbsp;提供专业团队将确保您的电气系统始终高效运行，并提供快速响应和可靠解决方案，以满足您的需求。
        </span>
      </div>
      <div class="text-wrapper_2 flex-col" @click="goH5Contact">
        <span class="text_16">渠道合作</span>
      </div>
      <div class="text-wrapper_3 flex-col" @click="sendMessage">
        <span class="text_17">预约体验</span>
      </div>
    </div>
    <div class="box_5 flex-col">
      <div class="text-wrapper_10 flex-row justify-between">
        <span class="text_18" @click="goProductCenter">产品中心</span>
        <span class="text_19" @click="goServiceCase('all')">标杆案例</span>
        <span class="text_20" @click="goH5Contact">渠道合作</span>
        <span class="text_21" @click="goaboutus">关于我们</span>
      </div>
      <!-- <div class="text-wrapper_11 flex-row">
        <span class="text_22">关于我们</span>
        <span class="text_24">联系我们</span>
      </div> -->
      <div class="text-group_21 flex-col">
        <span class="text_25">联系电话</span>
        <span class="text_26">021-3988&nbsp;2683</span>
      </div>
      <div class="text-group_22 flex-col">
        <span class="text_27">全国热线</span>
        <span class="text_28">177&nbsp;2132&nbsp;3870</span>
      </div>
      <div class="text-group_23 flex-col">
        <span class="text_29">江浙沪热线</span>
        <span class="text_30">139&nbsp;1881&nbsp;2305</span>
      </div>
      <div class="text-group_23 flex-col">
        <span class="text_29">投递邮箱</span>
        <span class="text_30">puwo888@163.com</span>
      </div>
      <div class="text-group_24 flex-col">
        <span class="text_31">公司地址</span>
        <span class="text_32">上海市奉贤区浦星公路9688弄6号6幢二层</span>
      </div>
      <div class="block_1 flex-col"></div>
      <div class="image-wrapper_2 flex-col">
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/ewm.png"
        />
      </div>
      <div class="text-group_25 flex-col">
        <span class="paragraph_1">
          Copyright&nbsp;&#64;&nbsp;
          {{
            new Date().getFullYear()
          }}.&nbsp;All&nbsp;rights&nbsp;reserved.&nbsp;
          <br />
          版权所有.&nbsp;上海扑沃智能科技有限公司
        </span>
        <span class="text_33">沪ICP备18037633号</span>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../bus.js";
export default {
  methods: {
    goProductCenter() {
      this.$router.push({
        name: "H5Product",
        params: {
          curFatherType: "nyszhjjfa",
          curSonType: "rjall",
        },
      });
    },
    goServiceCase(caseType) {
      this.$router.push({
        name: "H5ServiceCase",
        params: {
          caseType,
        },
      });
    },
    sendMessage() {
      bus.$emit("showExperEvent", true);
    },
    goH5Contact() {
      this.$router.push({
        name: "H5Contact",
      });
    },
    goaboutus() {
      this.$router.push({
        name: "H5Aboutus",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box_4 {
  background-image: url(./assets/img/bg1.png);
  margin-top: -0.26vw;
  padding: 14.13vw 4vw 21.33vw 4vw;
}

.text_14 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 6.4vw;
  letter-spacing: 0.6000000238418579px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 8.8vw;
  margin-right: 32.8vw;
}

.text_15 {
  width: 92vw;
  height: 21.34vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 3.73vw;
  letter-spacing: 0.3499999940395355px;
  text-align: left;
  line-height: 5.34vw;
  margin-top: 3.47vw;
}

.text-wrapper_2 {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 21px;
  border: 1px solid rgba(255, 255, 255, 1);
  margin: 12.53vw 42.93vw 0 0;
  padding: 2.4vw 15.46vw 2.4vw 15.46vw;
}

.text_16 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 4.26vw;
  letter-spacing: 0.4000000059604645px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.87vw;
}

.text-wrapper_3 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 21px;
  margin: 8vw 42.93vw 0 0;
  padding: 2.66vw 15.73vw 2.66vw 15.73vw;
}

.text_17 {
  overflow-wrap: break-word;
  color: rgba(52, 57, 192, 1);
  font-size: 4.26vw;
  letter-spacing: 0.4000000059604645px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 5.87vw;
}

.box_5 {
  background-color: rgba(0, 0, 0, 1);
  padding: 12.26vw 4vw 6.13vw 4vw;
}

.text-wrapper_10 {
  width: 92vw;
}

.text_18 {
  overflow-wrap: break-word;
  color: rgba(211, 211, 211, 1);
  font-size: 4.26vw;
  letter-spacing: 0.4000000059604645px;
  font-family: OPPOSans-R;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.6vw;
}

.text_19 {
  overflow-wrap: break-word;
  color: rgba(211, 211, 211, 1);
  font-size: 4.26vw;
  letter-spacing: 0.4000000059604645px;
  font-family: OPPOSans-R;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.6vw;
}

.text_20 {
  overflow-wrap: break-word;
  color: rgba(211, 211, 211, 1);
  font-size: 4.26vw;
  letter-spacing: 0.4000000059604645px;
  font-family: OPPOSans-R;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.6vw;
}

.text_21 {
  overflow-wrap: break-word;
  color: rgba(211, 211, 211, 1);
  font-size: 4.26vw;
  letter-spacing: 0.4000000059604645px;
  font-family: OPPOSans-R;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.6vw;
}

.text-wrapper_11 {
  width: 92vw;
  margin: 5.86vw 0 0 0;
}

.text_22 {
  overflow-wrap: break-word;
  color: rgba(211, 211, 211, 1);
  font-size: 4.26vw;
  letter-spacing: 0.4000000059604645px;
  font-family: OPPOSans-R;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.6vw;
  margin-right: 9.2vw;
}

.text_23 {
  overflow-wrap: break-word;
  color: rgba(211, 211, 211, 1);
  font-size: 4.26vw;
  letter-spacing: 0.4000000059604645px;
  font-family: OPPOSans-R;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.6vw;
}

.text_24 {
  overflow-wrap: break-word;
  color: rgba(211, 211, 211, 1);
  font-size: 4.26vw;
  letter-spacing: 0.4000000059604645px;
  font-family: OPPOSans-R;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.6vw;
}

.text-group_21 {
  margin: 11.46vw 46.13vw 0 0;
}

.text_25 {
  height: 5.34vw;
  overflow-wrap: break-word;
  color: rgba(156, 156, 156, 1);
  font-size: 3.73vw;
  letter-spacing: 0.3499999940395355px;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 4.8vw;
  margin-right: 30.4vw;
}

.text_26 {
  overflow-wrap: break-word;
  color: rgba(81, 140, 255, 1);
  font-size: 5.86vw;
  letter-spacing: 0.550000011920929px;
  font-family: Montserrat-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 7.2vw;
}

.text-group_22 {
  margin: 8vw 46.66vw 0 0;
}

.text_27 {
  overflow-wrap: break-word;
  color: rgba(156, 156, 156, 1);
  font-size: 3.73vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
  margin-right: 29.87vw;
}

.text_28 {
  overflow-wrap: break-word;
  color: rgba(81, 140, 255, 1);
  font-size: 5.86vw;
  letter-spacing: 0.550000011920929px;
  font-family: Montserrat-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 7.2vw;
}

.text-group_23 {
  margin: 8vw 46.66vw 0 0;
}

.text_29 {
  overflow-wrap: break-word;
  color: rgba(156, 156, 156, 1);
  font-size: 3.73vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
  margin-right: 26.14vw;
}

.text_30 {
  overflow-wrap: break-word;
  color: rgba(81, 140, 255, 1);
  font-size: 5.86vw;
  letter-spacing: 0.550000011920929px;
  font-family: Montserrat-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 7.2vw;
}

.text-group_24 {
  margin: 8vw 5.86vw 0 0;
}

.text_31 {
  overflow-wrap: break-word;
  color: rgba(156, 156, 156, 1);
  font-size: 3.73vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 5.34vw;
  margin-right: 70.67vw;
}

.text_32 {
  overflow-wrap: break-word;
  color: rgba(81, 140, 255, 1);
  font-size: 4.8vw;
  letter-spacing: 0.44999998807907104px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 6.67vw;
}

.block_1 {
  background-color: rgba(52, 52, 52, 1);
  width: 92vw;
  height: 0.27vw;
  margin-top: 11.47vw;
}

.image-wrapper_2 {
  border: 1px solid rgba(91, 91, 91, 1);
  margin: 8vw 54.13vw 0 0;
  padding: 2.66vw 2.4vw 2.4vw 2.66vw;
}

.image_6 {
  width: 32.27vw;
  height: 32.27vw;
}

.text-group_25 {
  margin: 6.93vw 17.33vw 0 0;
}

.paragraph_1 {
  width: 74.67vw;
  height: 9.07vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 3.2vw;
  letter-spacing: 0.30000001192092896px;
  text-transform: uppercase;
  font-weight: NaN;
  text-align: left;
  line-height: 4.54vw;
}

.text_33 {
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 3.2vw;
  letter-spacing: 0.30000001192092896px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 4.54vw;
  margin: 4vw 44vw 0 0;
}
</style>