<template>
  <div id="app">
    <div class="pc_box" v-if="!ish5">
      <Header
        v-if="routeName != 'SoftwareDetail' && routeName != 'ProductDetail'"
      />
      <header-product-detail
        v-if="routeName == 'SoftwareDetail' || routeName == 'ProductDetail'"
      ></header-product-detail>
      <router-view :key="$route.fullPath" />
      <Footter />
      <!-- 悬浮 -->
      <div class="fix_box1">
        <div class="fix_cls">
          <img loading="lazy" src="./assets/image/f1.png" alt="" />
          <span>联系热线</span>
        </div>
        <div class="fix_text">17721323870</div>
      </div>
      <div class="fix_box2">
        <div class="fix_cls">
          <img loading="lazy" src="./assets/image/f2.png" alt="" />
          <span>投递邮箱</span>
        </div>
        <div class="fix_text">puwo888@163.com</div>
      </div>
      <div class="fix_box3">
        <div class="fix_cls">
          <img loading="lazy" src="./assets/image/f3.png" alt="" />
          <span> 爱采购 </span>
        </div>
        <div class="fix_text" @click="goAcg">点击进入</div>
      </div>
      <div class="fix_box4">
        <div class="fix_cls">
          <img loading="lazy" src="./assets/image/f4.png" alt="" />
          <span>微信公众号</span>
        </div>
        <div class="ewm_cls">
          <img loading="lazy" src="./assets/image/ewm.jpg" alt="" />
        </div>
        <div class="fix_text">关注我们</div>
      </div>
    </div>
    <div class="h5_box" v-if="ish5">
      <h5header
        v-if="routeName != 'H5SoftwareDetail' && routeName != 'H5ProductDetail'"
      />
      <h5headerblue
        v-if="routeName == 'H5SoftwareDetail' || routeName == 'H5ProductDetail'"
      />
      <router-view :key="$route.fullPath" />
      <h5footter />
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import HeaderProductDetail from "@/components/HeaderProductDetail.vue";
import Footter from "@/components/Footter.vue";
import h5header from "@/views_h5/header/header.vue";
import h5headerblue from "@/views_h5/header/headerblue.vue";
import h5footter from "@/views_h5/footter/footter.vue";
export default {
  components: {
    Header,
    Footter,
    h5header,
    h5footter,
    HeaderProductDetail,
    h5headerblue,
  },
  data() {
    return {
      ish5: null,
      routeName: "",
      // 路由映射
      pctoh5: {
        Index: "Main",
        Products: "H5Product",
        ServiceCase: "H5ServiceCase",
        Cooperation: "H5Contact",
        ProductDetail: "H5ProductDetail",
        SoftwareDetail: "H5SoftwareDetail",
        CompanyIntro: "H5Aboutus",
      },
      h5topc: {
        Main: "Index",
        H5Product: "Products",
        H5ServiceCase: "ServiceCase",
        H5Contact: "Cooperation",
        H5ProductDetail: "ProductDetail",
        H5SoftwareDetail: "SoftwareDetail",
        H5Aboutus: "CompanyIntro",
      },
    };
  },
  created() {
    // 核心方法，主要 根据窗口的变化 来控制布局
    window.onresize = () => {
      window.screenWidth = document.body.clientWidth;
      // 第二步
      this.screenWidth = window.screenWidth;
      window.screenWidth >= 1200 ? (this.ish5 = false) : (this.ish5 = true);
    };
    window.onresize();
  },
  watch: {
    ish5: {
      handler() {
        this.changePage();
      },
    },
    $route: {
      handler() {
        this.routeName = this.$route.name;
      },
    },
  },
  mounted() {},
  methods: {
    changePage() {
      let routeName = window.sessionStorage.getItem("routeName");
      if (this.ish5 && this.pctoh5[routeName]) {
        this.$router.push({
          name: this.pctoh5[routeName],
        });
      } else if (!this.ish5 && this.h5topc[routeName]) {
        this.$router.push({
          name: this.h5topc[routeName],
        });
      }
    },
    goAcg() {
      window.open(
        "https://b2b.baidu.com/shop?name=%E4%B8%8A%E6%B5%B7%E6%89%91%E6%B2%83%E6%99%BA%E8%83%BD%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&xzhid=29264608&tpath=index",
        "_blank"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
// 右侧悬浮
.fix_box1 {
  display: flex;
  position: fixed;
  top: calc(50% - 8.333vw);
  right: -7.2917vw;
  z-index: 2222;
  cursor: pointer;
  transition: 0.5s;
}

.fix_box2 {
  display: flex;
  position: fixed;
  top: calc(50% - 4.1667vw);
  right: -7.2917vw;
  z-index: 2222;
  cursor: pointer;
  transition: 0.5s;
}
.fix_box3 {
  display: flex;
  position: fixed;
  top: calc(50%);
  right: -7.2917vw;
  z-index: 2222;
  cursor: pointer;
  transition: 0.5s;
}
.fix_box4 {
  display: flex;
  position: fixed;
  top: calc(50% + 4.1667vw);
  right: -7.2917vw;
  z-index: 2222;
  cursor: pointer;
  transition: 0.5s;
}
.fix_box1:hover {
  right: 0;
  transition: 0.5s;
}
.fix_box2:hover {
  right: 0;
  transition: 0.5s;
}
.fix_box3:hover {
  right: 0;
  transition: 0.5s;
}
.fix_box4:hover {
  right: 0;
  transition: 0.5s;
}
.fix_cls {
  background-color: rgba(0, 0, 0, 0.4) !important;
  border-radius: 0.2083vw;
  width: 3.8542vw;
  height: 3.8542vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 1.3542vw;
    height: 1.3542vw;
  }
  span {
    padding-top: 0.3125vw;
    font-size: 0.625vw;
    color: #fff;
  }
}
.fix_text {
  width: 7.2917vw;
  height: 3.8542vw;
  line-height: 3.8542vw;
  text-align: center;
  background: #004899;
  font-size: 0.6771vw;
  color: #fff;
  transition: all 1s;
}

.ewm_cls {
  position: absolute;
  top: 3.8542vw;
  right: 0;
  width: 7.1875vw;
  height: 7.1875vw;
  img {
    width: 100%;
    height: 100%;
  }
}

.h5_box {
  background-color: #fff;
}
</style>
<style>
* {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 5.2083vw;
}
a {
  color: #000;
}
#app {
  overflow-x: hidden;
  background-color: #f1fafa;
}
::-webkit-scrollbar {
  width: 0.2604vw;
  height: 2.0833vw;
}

::-webkit-scrollbar-thumb {
  background-color: #004da0;
  border-radius: 0.1563vw;
}
</style>

<style lang="css" src="./assets/common.css">
</style>
