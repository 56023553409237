<template>
  <div>
    <div
      class="header"
      :style="{ 'background-color': isTransparent ? 'transparent' : '#fff' }"
    >
      <div class="top">
        <div
          class="header_content flex-row"
          :style="{
            color: isTransparent ? '#fff' : '#222',
          }"
        >
          <div class="header_left">
            <div @click="go1" class="logo_box flex-col justify-between">
              <div class="logo_box_cls" v-if="isTransparent">
                <img
                  class="image_25"
                  referrerpolicy="no-referrer"
                  src="./assets/header/logo_en_t.png"
                />
                <img
                  class="image_26"
                  referrerpolicy="no-referrer"
                  src="./assets/header/logo_cn_t.png"
                />
              </div>
              <div class="logo_box_cls" v-if="!isTransparent">
                <img
                  class="image_25"
                  referrerpolicy="no-referrer"
                  src="./assets/header/logo_en.png"
                />
                <img
                  class="image_26"
                  referrerpolicy="no-referrer"
                  src="./assets/header/logo_cn.png"
                />
              </div>
            </div>
            <div @click="go1" class="text_121">首页</div>
            <div
              class="menu_item_box"
              @mouseover="showSolution"
              @mouseout="hiddenSolution"
              @click="goShowPage('nyszhjjfa')"
            >
              <span ref="fangantxt">解决方案</span>
              <img
                v-if="isTransparent"
                ref="fanganimg"
                class="arrow_down_cls"
                referrerpolicy="no-referrer"
                src="./assets/header/downarrow.png"
              />
              <img
                v-if="!isTransparent"
                ref="fanganimg"
                class="arrow_down_cls"
                referrerpolicy="no-referrer"
                src="./assets/header/downarrow_s.png"
              />
            </div>
            <div
              class="menu_item_box"
              @mouseover="showproducts"
              @mouseout="hiddenproducts"
              @click="goShowPage('nywlwyjcp')"
            >
              <span ref="chanpintxt">产品中心</span>
              <img
                v-if="isTransparent"
                ref="chanpinimg"
                class="arrow_down_cls"
                referrerpolicy="no-referrer"
                src="./assets/header/downarrow.png"
              />
              <img
                v-if="!isTransparent"
                ref="chanpinimg"
                class="arrow_down_cls"
                referrerpolicy="no-referrer"
                src="./assets/header/downarrow_s.png"
              />
            </div>

            <div
              class="menu_item_box"
              @mouseover="showCase"
              @mouseout="hiddenCase"
              @click="goServiceCase"
            >
              <span ref="anlitxt">标杆案例</span>
              <img
                v-if="isTransparent"
                ref="anliimg"
                class="arrow_down_cls"
                referrerpolicy="no-referrer"
                src="./assets/header/downarrow.png"
              />
              <img
                v-if="!isTransparent"
                ref="anliimg"
                class="arrow_down_cls"
                referrerpolicy="no-referrer"
                src="./assets/header/downarrow_s.png"
              />
            </div>
            <div
              class="menu_item_box"
              @mouseover="showCloud"
              @mouseout="hiddenCloud"
            >
              <span ref="ypttxt">云平台</span>
              <img
                v-if="isTransparent"
                ref="yptimg"
                class="arrow_down_cls"
                referrerpolicy="no-referrer"
                src="./assets/header/downarrow.png"
              />
              <img
                v-if="!isTransparent"
                ref="yptimg"
                class="arrow_down_cls"
                referrerpolicy="no-referrer"
                src="./assets/header/downarrow_s.png"
              />
            </div>
            <div class="menu_item_box" @click="goCooperation">渠道合作</div>
            <div class="menu_item_box" @click="goaboutus">关于我们</div>
          </div>
          <div class="header_right">
            <div class="image-wrapper_10">
              <img
                v-if="isTransparent"
                class="thumbnail_8"
                referrerpolicy="no-referrer"
                src="./assets/header/phoneicon.png"
              />
              <img
                v-if="!isTransparent"
                class="thumbnail_8"
                referrerpolicy="no-referrer"
                src="./assets/header/phone_blue.png"
              />
              <div class="text_128">021-39882683</div>
            </div>
            <div
              class="flex-col"
              :class="isTransparent ? 'ty_transparent_cls' : 'ty_blue_cls'"
              @click="showDrawer"
              style="cursor: pointer"
            >
              <span class="text_129">立即体验</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品相关 -->
      <div
        class="product_center"
        @mouseover="showproducts"
        @mouseout="hiddenproducts"
      >
        <div class="layout">
          <div class="mask_group">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/dlybxl.png"
                alt=""
              />
            </div>
            <div class="group_text">
              <div class="group_title">电力仪表系列</div>

              <div class="item_title" @click="goProductDetail('dgndcyb')">
                PWT830系列多功能电力仪表
              </div>
              <div class="item_title" @click="goProductDetail('dxdgsdnb')">
                DDS1852单相导轨式电能表
              </div>
              <div class="item_title" @click="goProductDetail('sxdgsdnb')">
                DTS1852三相导轨式电能表
              </div>
              <div class="item_title" @click="goProductDetail('dxdgsyffdnb')">
                DTS1852Y单相导轨式预付费电能表
              </div>
              <div class="item_title" @click="goProductDetail('sxdgsyffdnb')">
                DTS1852Y三相导轨式预付费电能表
              </div>
              <div class="item_title" @click="goProductDetail('600xwjzhbhzz')">
                PWT600型微机综合保护装置
              </div>
              <div class="item_title" @click="goProductDetail('601xwjzhbhzz')">
                PWT601型微机综合保护装置
              </div>
              <div class="item_title" @click="goProductDetail('dxdgsfkzndb')">
                DDS1852Y-N单相导轨式费控智能电表
              </div>
              <div class="item_title" @click="goProductDetail('sxdgsfkzndb')">
                DTS1852Y-N三相导轨式费控智能电表
              </div>
              <div class="item_title" @click="goProductDetail('dxbgsyffdnb')">
                DDS1852-B单相壁挂式预付费电能表
              </div>
              <div class="item_title" @click="goProductDetail('sxbgsyffdnb')">
                DTS1852-B三相壁挂式预付费电能表
              </div>
              <div class="item_title" @click="goProductDetail('dyhdnbptx')">
                PWD800型多用户电能表（普通型）
              </div>
              <div class="item_title" @click="goProductDetail('dyhyffdb')">
                PWD800Y型多用户预付费电表
              </div>

              <div class="item_title" @click="goProductDetail('dxdcyb')">
                PWT810系列单相电测仪表
              </div>
              <div class="item_title" @click="goProductDetail('sxdcyb')">
                PWT830系列三相电测仪表
              </div>
            </div>
          </div>
          <div class="mask_group">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/snkqcgq.png"
                alt=""
              />
            </div>
            <div class="group_text">
              <div class="group_title">室内空气传感器</div>
              <div class="item_title" @click="goProductDetail('dgnkqzlcgq')">
                PWT-AQ-MFS（多功能空气质量传感器）
              </div>
              <div class="item_title" @click="goProductDetail('yyhtcgq')">
                PWT-AQ-COS（一氧化碳传感器）
              </div>
              <div class="item_title" @click="goProductDetail('eyhtcgq')">
                PWT-AQ-CDS（二氧化碳传感器）
              </div>
              <div class="item_title" @click="goProductDetail('jqcgq')">
                PWT-AQ-FGS（甲醛传感器）
              </div>
              <div class="item_title" @click="goProductDetail('pm2.510cgq')">
                PWT-AQ-PM2.5/10（PM2.5/10传感器）
              </div>
              <div class="item_title" @click="goProductDetail('wsdcgq')">
                PWT-AQ-THS（温湿度传感器）
              </div>
            </div>
          </div>
          <div class="mask_group">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/txsb.png"
                alt=""
              />
            </div>
            <div class="group_text">
              <div class="group_title">通讯设备</div>
              <div class="item_title" @click="goProductDetail('dgc4swlwg')">
                PWT-DGc4s物联网关
              </div>
              <div class="item_title" @click="goProductDetail('dgs2zncjsb')">
                PWT-DGs2智能采集设备
              </div>
              <div class="item_title" @click="goProductDetail('dgs4zncjsb')">
                PWT-DGs4智能采集设备
              </div>
              <div class="item_title" @click="goProductDetail('dgs8zncjsb')">
                PWT-DGs8智能采集设备
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 解决方案 -->
      <div
        class="solution_center"
        @mouseover="showSolution"
        @mouseout="hiddenSolution"
      >
        <div class="layout">
          <div class="mask_group">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/nhgl.png"
                alt=""
              />
            </div>
            <div class="group_text">
              <div class="group_title">能耗管理</div>
              <div class="item_title" @click="goSoftDetail('sdyhffglxt')">
                水电预付费/后付费管理系统
              </div>
              <div class="item_title" @click="goSoftDetail('zhnhfwypt')">
                综合能源管理系统
              </div>
              <div class="item_title" @click="goSoftDetail('gffdjkxt')">
                光伏发电监控系统
              </div>
            </div>
          </div>
          <div class="mask_group">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/dljk.png"
                alt=""
              />
            </div>
            <div class="group_text">
              <div class="group_title">电力监控</div>
              <div class="item_title" @click="goSoftDetail('dljkxt')">
                电力监控系统
              </div>
              <div class="item_title" @click="goSoftDetail('pdznfzjkxt')">
                配电智能辅助监控系统
              </div>
              <div class="item_title" @click="goSoftDetail('dlywypt')">
                电力运维云平台
              </div>
            </div>
          </div>
          <div class="mask_group">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/hjjc.png"
                alt=""
              />
            </div>
            <div class="group_text">
              <div class="group_title">环境监测</div>
              <div class="item_title" @click="goSoftDetail('snkqzljcxt')">
                室内空气质量监测系统
              </div>
              <div class="item_title" @click="goSoftDetail('zhhbjcpt')">
                智慧环保监测平台
              </div>
            </div>
          </div>
          <div class="mask_group">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/lyjzm.png"
                alt=""
              />
            </div>
            <div class="group_text">
              <div class="group_title">楼宇及照明</div>
              <div class="item_title" @click="goSoftDetail('zyktjljkglxt')">
                BA楼宇智控系统
              </div>
              <div class="item_title" @click="goSoftDetail('znzmglxt')">
                智能照明管理系统
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 服务案例 -->
      <div class="case_center" @mouseover="showCase" @mouseout="hiddenCase">
        <div class="layout">
          <div class="mask_group" @click="goServiceCase('syzht')">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/syzht.png"
                alt=""
              />
            </div>
            <div class="item_name">商业综合体</div>
          </div>
          <div class="mask_group" @click="goServiceCase('jyyl')">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/yljy.png"
                alt=""
              />
            </div>
            <div class="item_name">教育医疗</div>
          </div>
          <div class="mask_group" @click="goServiceCase('zhyq')">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/zhyq.png"
                alt=""
              />
            </div>
            <div class="item_name">智慧园区</div>
          </div>
          <div class="mask_group" @click="goServiceCase('jdgy')">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/jdgy.png"
                alt=""
              />
            </div>
            <div class="item_name">酒店公寓</div>
          </div>
          <div class="mask_group" @click="goServiceCase('gkqy')">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/gkqy.png"
                alt=""
              />
            </div>
            <div class="item_name">工矿企业</div>
          </div>
          <div class="mask_group" @click="goServiceCase('gfzn')">
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/gfcn.png"
                alt=""
              />
            </div>
            <div class="item_name">光伏储能</div>
          </div>
          <!-- <div class="mask_group">
            <div class="imgcls">
              <img loading="lazy" src="../assets/image/solution3.png" alt="" />
              <div class="item_name">其他</div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 云平台服务 -->
      <div class="cloud_center" @mouseover="showCloud" @mouseout="hiddenCloud">
        <div class="layout">
          <div
            class="mask_group"
            @click="openNewWindow('http://power.puwo-tech.com/')"
          >
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/dlywypt.png"
                alt=""
              />
            </div>
            <div class="item_name">电力运维云平台</div>
          </div>
          <div
            class="mask_group"
            @click="openNewWindow('http://water.puwo-tech.com/')"
          >
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/sxxglpt.png"
                alt=""
              />
            </div>
            <div class="item_name">水信息管理平台</div>
          </div>
          <div
            class="mask_group"
            @click="openNewWindow('http://prepay.puwo-tech.com/')"
          >
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/yffypt.png"
                alt=""
              />
            </div>
            <div class="item_name">预付费云平台</div>
          </div>
          <div
            class="mask_group"
            @click="openNewWindow('http://energy.puwo-tech.com/')"
          >
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/zhnhypt.png"
                alt=""
              />
            </div>
            <div class="item_name">综合能耗云平台</div>
          </div>
          <div
            class="mask_group"
            @click="openNewWindow('http://aqms.puwo-tech.com/')"
          >
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/kqzljcpt.png"
                alt=""
              />
            </div>
            <div class="item_name">空气质量监测平台</div>
          </div>
          <div
            class="mask_group"
            @click="openNewWindow('http://pv.puwo-tech.com/')"
          >
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/gfywypt.png"
                alt=""
              />
            </div>
            <div class="item_name">光伏运维云平台</div>
          </div>
          <div
            class="mask_group"
            @click="openNewWindow('http://feecontrol.puwo-tech.com/')"
          >
            <div class="img_box_cls">
              <img
                class="img_cls"
                loading="lazy"
                src="./assets/header/ycfkypt.png"
                alt=""
              />
            </div>
            <div class="item_name">远程费控云平台</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 体验抽屉 -->
    <div class="drawer_box" :class="drawerVisible ? '' : 'notshow'">
      <div class="title_box">
        <div class="block_24 flex-row justify-between">
          <span class="text_33">预约体验</span>
          <img
            @click="showDrawer"
            class="thumbnail_8"
            referrerpolicy="no-referrer"
            src="./assets/header/closeicon.png"
          />
        </div>
        <div class="text_34">请提交需求，我们马上跟您取得联系：</div>
      </div>
      <div class="form_box">
        <el-form
          :model="formdata"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <div class="formbox_line1">
            <el-form-item prop="name" class="line1_item">
              <div class="form_label">姓名</div>
              <el-input
                v-model="formdata.name"
                placeholder="请输入您的姓名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="companyname" class="line1_item">
              <div class="form_label">公司名称</div>
              <el-input
                v-model="formdata.companyname"
                placeholder="请输入您的姓名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="phone" class="line1_item">
              <div class="form_label">手机号</div>
              <el-input
                v-model="formdata.phone"
                placeholder="请输入您的手机号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="email" class="line1_item">
              <div class="form_label">邮箱</div>
              <el-input
                v-model="formdata.email"
                placeholder="请输入您的邮箱"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item prop="profession">
            <div class="form_label">公司所在行业</div>
            <el-input
              v-model="formdata.profession"
              placeholder="请输入公司所在行业"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <div class="form_label">
              如果您有其他想了解的信息或者疑问，欢迎给我们留言（选填）
            </div>
            <el-input
              v-model="formdata.remark"
              type="textarea"
              :rows="6"
              placeholder="请填写您的内容"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button @click="sumbit" style="float: right">立即预约</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "./pcbus.js";
export default {
  name: "Header",
  data() {
    return {
      logoimg: "",
      isTransparent: true,
      drawerVisible: false,
      formdata: {
        email: "",
        name: "",
        phone: "",
        remark: "",
        email: "",
        tel: "",
        companyname: "",
        profession: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        companyname: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        profession: [
          { required: true, message: "请输入公司所在行业", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: "请输入正确的手机号码",
          },
        ],
        email: [{ required: true, message: "请输入您的邮箱", trigger: "blur" }],
        // remark: [{ required: true, message: "请输入留言", trigger: "blur" }],
      },
      submitURL: window.gurl.URL + "customerinfo/submit",
    };
  },
  created() {
    bus.$on("pcshowExperEvent", (data) => {
      this.showDrawer();
    });
  },
  mounted() {
    window.addEventListener("scroll", this.productScrollListener);
  },
  destroyed() {
    window.removeEventListener("scroll", this.productScrollListener);
  },
  methods: {
    async sumbit() {
      const res1 = await this.axios.post(this.submitURL, {
        ...this.formdata,
      });
      if (res1.rescode == 200) {
        this.$message.success({
          message: "提交成功，请等待客服联系",
          offset: 120,
        });
      }
    },
    showDrawer() {
      this.drawerVisible = !this.drawerVisible;
    },
    productScrollListener() {
      //获取操作元素最顶端到页面顶端的垂直距离
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 10) {
        this.isTransparent = false;
        this.$refs.chanpintxt.style.color = "#222";
        this.$refs.fangantxt.style.color = "#222";
        this.$refs.anlitxt.style.color = "#222";
        this.$refs.ypttxt.style.color = "#222";
      }
      if (scrollTop < 10) {
        this.isTransparent = true;
        this.$refs.chanpintxt.style.color = "#fff";
        this.$refs.fangantxt.style.color = "#fff";
        this.$refs.anlitxt.style.color = "#fff";
        this.$refs.ypttxt.style.color = "#fff";
      }
    },
    openNewWindow(url) {
      window.open(url, "_blank");
    },
    goSoftDetail(deviceid) {
      this.$router.push({
        name: "SoftwareDetail",
        params: {
          deviceid,
        },
      });
      this.hiddenproducts();
    },
    goProductDetail(deviceid) {
      this.$router.push({
        name: "ProductDetail",
        params: {
          deviceid,
        },
      });
      this.hiddenproducts();
    },
    goaboutus() {
      this.$router.push({
        name: "CompanyIntro",
      });
    },
    goCooperation() {
      this.$router.push({
        name: "Cooperation",
      });
    },
    goProduct(val) {
      this.$router.push({
        name: "Products",
        params: {
          cateid: val,
        },
      });
      this.hiddenproducts();
    },
    goServiceCase(str) {
      this.$router.push({
        name: "ServiceCase",
      });
      this.hiddenCase();
      setTimeout(() => {
        document.querySelector("#" + str).scrollIntoView(true);
      }, 300);
    },
    showproducts() {
      let dom = document.getElementsByClassName("product_center");
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 10) {
        this.$refs.chanpintxt.style.color = "#3439C0";
      } else {
        this.$refs.chanpintxt.style.color = "#FF7B34";
      }
      this.$refs.chanpinimg.style.transform = "rotate(-180deg)";
      dom[0].style.opacity = "1";
      dom[0].style.visibility = "visible";
      dom[0].style.transition = "0.2s";
    },
    hiddenproducts() {
      let dom = document.getElementsByClassName("product_center");
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 10) {
        this.$refs.chanpintxt.style.color = "#222";
      } else {
        this.$refs.chanpintxt.style.color = "#fff";
      }
      this.$refs.chanpinimg.style.transform = "rotate(0deg)";
      dom[0].style.opacity = "0";
      dom[0].style.visibility = "hidden";
      dom[0].style.transition = "0.2s";
    },
    showSolution() {
      let dom = document.getElementsByClassName("solution_center");
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 10) {
        this.$refs.fangantxt.style.color = "#3439C0";
      } else {
        this.$refs.fangantxt.style.color = "#FF7B34";
      }
      this.$refs.fanganimg.style.transform = "rotate(-180deg)";
      dom[0].style.opacity = "1";
      dom[0].style.visibility = "visible";
      dom[0].style.transition = "0.2s";
    },
    hiddenSolution() {
      let dom = document.getElementsByClassName("solution_center");
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 10) {
        this.$refs.fangantxt.style.color = "#222";
      } else {
        this.$refs.fangantxt.style.color = "#fff";
      }
      this.$refs.fanganimg.style.transform = "rotate(0deg)";
      dom[0].style.opacity = "0";
      dom[0].style.visibility = "hidden";
      dom[0].style.transition = "0.2s";
    },
    showCase() {
      let dom = document.getElementsByClassName("case_center");
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 10) {
        this.$refs.anlitxt.style.color = "#3439C0";
      } else {
        this.$refs.anlitxt.style.color = "#FF7B34";
      }
      this.$refs.anliimg.style.transform = "rotate(-180deg)";
      dom[0].style.opacity = "1";
      dom[0].style.visibility = "visible";
      dom[0].style.transition = "0.2s";
    },
    hiddenCase() {
      let dom = document.getElementsByClassName("case_center");
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 10) {
        this.$refs.anlitxt.style.color = "#222";
      } else {
        this.$refs.anlitxt.style.color = "#fff";
      }
      this.$refs.anliimg.style.transform = "rotate(0deg)";
      dom[0].style.opacity = "0";
      dom[0].style.visibility = "hidden";
      dom[0].style.transition = "0.2s";
    },
    showCloud() {
      let dom = document.getElementsByClassName("cloud_center");
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 10) {
        this.$refs.ypttxt.style.color = "#3439C0";
      } else {
        this.$refs.ypttxt.style.color = "#FF7B34";
      }
      this.$refs.yptimg.style.transform = "rotate(-180deg)";
      dom[0].style.opacity = "1";
      dom[0].style.visibility = "visible";
      dom[0].style.transition = "0.2s";
    },
    hiddenCloud() {
      let dom = document.getElementsByClassName("cloud_center");
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 10) {
        this.$refs.ypttxt.style.color = "#222";
      } else {
        this.$refs.ypttxt.style.color = "#fff";
      }
      this.$refs.yptimg.style.transform = "rotate(0deg)";
      dom[0].style.opacity = "0";
      dom[0].style.visibility = "hidden";
      dom[0].style.transition = "0.2s";
    },
    go1() {
      this.$router.push("/");
    },
    goShowPage(val) {
      if (val == "nyszhjjfa" || val == "nywlwyjcp") {
        let dom = document.getElementsByClassName("product_center");
        dom[0].style.opacity = "0";
        dom[0].style.visibility = "hidden";
        let dom1 = document.getElementsByClassName("solution_center");
        dom1[0].style.opacity = "0";
        dom1[0].style.visibility = "hidden";
        this.$router.push({
          name: "Products",
          params: {
            curFatherType: val,
            curSonType: val == "nyszhjjfa" ? "rjall" : "yjall",
          },
        });
      } else if (val == "fwal") {
        let dom2 = document.getElementsByClassName("case_center");
        dom2[0].style.opacity = "0";
        dom2[0].style.visibility = "hidden";
        this.$router.push({
          name: "ServiceCase",
        });
      }
    },
    gosoft() {
      // if (dom[0].style.visibility == "hidden") {
      //   dom[0].style.opacity = "1";
      //   dom[0].style.visibility = "visible";
      //   dom[0].style.transition = "0.2s";
      // } else {
      //   dom[0].style.opacity = "0";
      //   dom[0].style.visibility = "hidden";
      //   dom[0].style.transition = "0.2s";
      // }
      // dom[0].style.transition = "0.2s";
    },
    gohard() {
      let dom = document.getElementsByClassName("product_center");
      // if (dom[0].style.visibility == "hidden") {
      //   dom[0].style.opacity = "1";
      //   dom[0].style.visibility = "visible";
      //   dom[0].style.transition = "0.2s";
      // } else {
      //   dom[0].style.opacity = "0";
      //   dom[0].style.visibility = "hidden";
      //   dom[0].style.transition = "0.2s";
      // }
      this.$router.push("/products");
      dom[0].style.opacity = "0";
      dom[0].style.visibility = "hidden";
      // dom[0].style.transition = "0.2s";
    },
    go3() {
      this.$router.push("/service-case");
      this.hiddenCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.logo1 {
  height: 60px;
  width: 132px;
  padding: 0;
  text-decoration: none;
  display: block;
  background-size: contain;
  cursor: pointer;
}
.header {
  width: 100%;
  height: 4.5vw;
  // background: rgba(0, 0, 0, 1);
  position: fixed;
  z-index: 9999;
  top: 0;
  // box-shadow: 0 0 0.5vw 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0.5vw 0 rgba(0, 0, 0, 0.1);
}
.right {
  /* color: #000; */
  display: flex;
  width: 70%;
  height: 100%;
  align-items: center;
  margin-left: 140px;
  justify-content: space-around;
}
.subtit {
  font-family: "Open Sans" !important;
  font-weight: 400;
  color: #fff;
  line-height: 23px;
  z-index: 100;
  cursor: pointer;
  font-size: 18px;
}
.subtit:hover {
  border-bottom: 2px solid;
  /* padding-bottom: 5px; */
}
.top {
  width: 92vw;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.left {
  padding-left: 50px;
  display: flex;
  align-items: center;

  .logo-text {
    height: 92px;
    width: 250px;
    color: #fff;
    font-size: 16px;
    margin: 0 0 0 -4px;
    font-weight: 700;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

a {
  font-family: "Open Sans" !important;
  font-weight: 400;
}

.product_center,
.solution_center {
  width: 76vw;
  position: absolute;
  left: 12.45vw;
  top: 4vw;
  box-shadow: 0px 4px 12px 0px rgba(109, 109, 109, 0.2);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  visibility: hidden;
  opacity: 0;
  .layout {
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    padding: 1.5vw 2vw;
    .mask_group {
      display: flex;
      margin-right: 3.5vw;
      .img_box_cls {
        background-color: rgba(255, 240, 232, 1);
        border-radius: 50%;
        height: 1.67vw;
        width: 1.67vw;
        display: flex;
        justify-content: center;
        align-items: center;
        .img_cls {
          width: 0.84vw;
          height: 0.84vw;
        }
      }
      .group_text {
        margin-left: 0.6vw;
        .group_title {
          font-size: 0.8333vw;
          font-weight: 700;
          height: 1.7vw;
          line-height: 1.7vw;
          margin-bottom: 0.16vw;
        }
        .item_title {
          height: 1.6667vw;
          line-height: 1.6667vw;
          font-size: 0.7292vw;
          cursor: pointer;
        }
        .item_title:hover {
          color: #409eff;
        }
      }
    }
    .mask_group:last-child {
      border-right: none;
    }
  }
}

.block {
  height: 92px;
  line-height: 92px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 1px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #409eff;
  border-radius: 3px;
}

.case_center,
.cloud_center {
  width: 76vw;
  position: absolute;
  left: 12.45vw;
  top: 4vw;
  box-shadow: 0px 4px 12px 0px rgba(109, 109, 109, 0.2);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  visibility: hidden;
  opacity: 0;
  .layout {
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    padding: 2vw 3vw;
    .mask_group {
      margin-right: 4vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 0.2s;
      cursor: pointer;
      .img_box_cls {
        background-color: rgba(255, 240, 232, 1);
        border-radius: 50%;
        height: 2.71vw;
        width: 2.71vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5vw;
        .img_cls {
          width: 1.52vw;
          height: 1.52vw;
        }
      }
      .item_name {
        font-size: 0.7292vw;
        font-family: OPPOSans, OPPOSans;
        font-weight: normal;
        color: #222222;
      }
    }

    .mask_group:hover {
      transform: scale(1.05);
      transition: all 0.2s;
    }
  }
}

// 产品中心和解决方案合并
::v-deep .el-tabs__item {
  color: #fff;
  font-size: 18px;
  height: 60px;
}
::v-deep .el-tabs__item.is-active {
  color: #409eff;
}
::v-deep .el-tabs {
  width: 90%;
  margin: 0 auto;
  margin-top: 1%;
}

// 右上角联系方式
.contact_cls {
  color: #fff;
  .contact_item {
    display: flex;
    // align-items: center;
  }
  .contact_img {
    width: 22px;
    height: 22px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .contact_txt {
    font-size: 16px;
  }
}

// 重构css
// 头部
.header_content {
  width: 92vw;
  height: 4.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  .header_left {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .header_right {
    display: flex;
    align-items: center;
  }
}

.logo_box {
  width: 3.55vw;
  height: 2.35vw;
}

.image_25 {
  width: 3.55vw;
  height: 1.62vw;
  cursor: pointer;
}

.image_26 {
  width: 3.03vw;
  height: 0.73vw;
  margin-left: 0.37vw;
  cursor: pointer;
}

.text_121 {
  font-size: 0.83vw;
  letter-spacing: 0.4000000059604645px;
  font-family: OPPOSans-R;
  margin-left: 5.78vw;
  cursor: pointer;
}

.menu_item_box {
  height: 100%;
  font-size: 0.83vw;
  letter-spacing: 0.4000000059604645px;
  font-family: OPPOSans-R;
  margin-left: 1.82vw;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.arrow_down_cls {
  width: 0.42vw;
  height: 0.21vw;
  margin-left: 0.2vw;
  transition: all 0.2s;
}

.image-wrapper_10 {
  height: 1.05vw;
  display: flex;
  align-items: center;
}

.thumbnail_8 {
  height: 1.05vw;
  width: 1.05vw;
  cursor: pointer;
}

.text_128 {
  font-size: 0.93vw;
  letter-spacing: 0.44999998807907104px;
  font-family: OPPOSans-H;
  margin-left: 0.6vw;
}

.ty_transparent_cls {
  background-color: rgba(255, 123, 52, 1);
  color: #fff;
  border-radius: 18px;
  height: 1.88vw;
  width: 5.21vw;
  margin-left: 2.39vw;
}
.ty_blue_cls {
  background-color: rgba(52, 57, 192, 1);
  color: #fff;
  border-radius: 18px;
  height: 1.88vw;
  width: 5.21vw;
  margin-left: 2.39vw;
}

.text_129 {
  width: 3.44vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  font-size: 0.83vw;
  letter-spacing: 0.4000000059604645px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.31vw 0 0 0.88vw;
}

// 抽屉相关样式
.drawer_box {
  width: 40vw;
  height: calc(100vh - 4.5vw);
  position: fixed;
  right: 0;
  top: 4.5vw;
  background-color: #fafafa;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), #aaa);
  z-index: 20 !important;
  transition: 0.3s;
  padding: 3.17vw 2.86vw;
  .title_box {
    .block_24 {
      height: 1.72vw;
    }

    .text_33 {
      width: 5vw;
      height: 1.72vw;
      overflow-wrap: break-word;
      color: rgba(52, 57, 192, 1);
      font-size: 1.25vw;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      line-height: 1.72vw;
    }

    .thumbnail_8 {
      width: 0.94vw;
      height: 0.94vw;
      margin-top: 0.32vw;
    }

    .text_34 {
      height: 1.15vw;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 0.83vw;
      font-weight: NaN;
      text-align: left;
      white-space: nowrap;
      line-height: 1.15vw;
      margin-top: 0.5vw;
    }
  }

  .form_box {
    margin-top: 1vw;
    .formbox_line1 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .line1_item {
        width: 49%;
      }
    }
    .form_label {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 0.7292vw;
      color: #666666;
      line-height: 20px;
      text-align: left;
      margin-bottom: 0.3vw;
    }
  }
}
.notshow {
  right: -40vw;
  transition: 0.3s;
}

.el-button {
  width: 200px;
  height: 50px;
  color: #fff;
  background: #3439c0;
  border-radius: 0px;
}
::v-deep .el-input__inner {
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  background-color: #f3f4f5;
}
::v-deep .el-textarea__inner {
  background-color: #f3f4f5;
}

::v-deep .el-select {
  width: 260px;
  height: 50px;
  .el-input__inner {
    border-radius: 0;
    height: 50px;
    line-height: 50px;
  }
  .el-input__inner:focus {
    border-color: transparent;
  }
}
</style>
