<template>
  <div class="products" id="zyyw_box">
    <div class="box_32 flex-row">
      <div class="box_3 flex-col"></div>
      <div class="text-wrapper_49 flex-col justify-between">
        <span class="text_29">能源数字化解决方案</span>
        <span class="text_30">Digital&nbsp;energy&nbsp;solutions</span>
      </div>
      <span class="text_31" @click="goProductCenter('nyszhjjfa', 'rjall')"
        >更多&gt;</span
      >
    </div>
    <div class="tabs_box">
      <div class="tabs_soft">
        <div
          class="tab_item_box"
          v-for="(item, index) in softList"
          :key="index"
          @click="chooseSoft(index, item.value)"
        >
          <div class="soft_item_box" :class="item.choose ? '' : 'no_selected'">
            <div>
              <img class="img_cls" :src="item.icon" alt="" />
            </div>
            <div class="soft_name">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section_9">
      <div class="img_box_cls">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          :src="curSoftInfo.img"
        />
      </div>
      <div class="intro_box_cls">
        <div class="intro_title flex-col">
          <span class="text_14">{{ curSoftInfo.intro }}</span>
          <span class="text_15">{{ curSoftInfo.title }}</span>
        </div>
        <div class="text_16">技术特点</div>
        <div class="section_33">
          <div
            v-for="(item, index) in curSoftInfo.features"
            :key="index"
            class="feature_item flex-row"
          >
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="../../assets/index/feature_point.png"
            />
            <span class="feature_txt">{{ item }}</span>
          </div>
        </div>
        <div class="section_36 flex-row justify-between">
          <div class="text-wrapper_4">
            <div class="text_17" @click="goOhterWeb(curSoftInfo.url)">
              立即体验
            </div>
          </div>
          <div
            class="section_10 flex-row justify-between"
            @click="goDetailPage(curSoftInfo.detailval)"
          >
            <span class="text_18">READ&nbsp;MORE</span>
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="../../assets/index/right_blue.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="hard_box">
      <div class="box_38 flex-row">
        <div class="section_14 flex-col"></div>
        <div class="text-wrapper_50 flex-col justify-between">
          <span class="text_41">能源物联网产品</span>
          <span class="text_42">Energy&nbsp;IoT&nbsp;products</span>
        </div>
        <span class="text_43" @click="goProductCenter('nywlwyjcp', 'yjall')"
          >更多&gt;</span
        >
      </div>
      <div class="hard_content">
        <div class="hard_item" @click="goDetail('/product-detail/sxdcyb')">
          <img class="yj_img_cls" src="../../assets/image/dlybyj.png" />
          <div class="hard_name">电力仪表</div>
        </div>
        <div
          class="hard_item"
          @click="goDetail('/product-detail/600xwjzhbhzz')"
        >
          <img
            class="yj_img_cls"
            src="../../assets/image/wjzhbhzz1.png"
            alt=""
          />
          <div class="hard_name">综保</div>
        </div>
        <div class="hard_item" @click="goDetail('/product-detail/dgnkqzlcgq')">
          <img class="yj_img_cls" src="../../assets/image/kqzlyj5.png" alt="" />
          <div class="hard_name">空气质量传感器</div>
        </div>
        <div class="hard_item" @click="goDetail('/product-detail/dgc4swlwg')">
          <img class="yj_img_cls" src="../../assets/image/wlwgyj1.png" alt="" />
          <div class="hard_name">物联网关</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 数字化解决方案
import yffypt from "../../assets/index/yffypt.png";
import dlywypt from "../../assets/index/dlywypt.png";
import kqzljcpt from "../../assets/index/kqzljcpt.png";
import zhnhypt from "../../assets/index/zhnhypt.png";
import gfywypt from "../../assets/index/gfywypt.png";
// 软件界面
import yffjm from "../../assets/index/yffjm.png";
import dlywyptjm from "../../assets/index/dlywyptjm.png";
import kqzljcxtjm from "../../assets/index/kqzljcxtjm.png";
import zhnhyptjm from "../../assets/index/zhnhyptjm.png";
import gfywyptjm from "../../assets/index/gfywyptjm.png";
export default {
  data() {
    return {
      softList: [
        {
          label: "预付费云平台",
          value: "yffypt",
          icon: yffypt,
          choose: true,
        },
        {
          label: "电力运维云平台",
          value: "dlywypt",
          icon: dlywypt,
          choose: false,
        },

        {
          label: "空气质量监测平台",
          value: "kqzljcpt",
          icon: kqzljcpt,
          choose: false,
        },
        {
          label: "综合能耗云平台",
          value: "zhnhypt",
          icon: zhnhypt,
          choose: false,
        },
        {
          label: "光伏运维云平台",
          value: "gfywypt",
          icon: gfywypt,
          choose: false,
        },
      ],
      cursoftVal: "yffypt",
      softinfoList: {
        yffypt: {
          img: yffjm,
          intro: "智能远程监控,终端数据可视化",
          title: "水电预付费/后付费管理系统",
          url: "http://prepay.puwo-tech.com/",
          detailval: "sdyhffglxt",
          features: [
            "全能耗在线监测",
            "可视化数据呈现",
            "设备批量管理",
            "尖峰平谷报表查询",
            "APP移动终端服务",
            "能耗数据报表查询",
          ],
        },
        dlywypt: {
          img: dlywyptjm,
          intro: "电力运维云平台",
          title: "电力运维云平台",
          url: "http://power.puwo-tech.com/",
          detailval: "dlywypt",
          features: [
            "全能耗在线监测",
            "可视化数据呈现",
            "设备批量管理",
            "尖峰平谷报表查询",
            "APP移动终端服务",
            "能耗数据报表查询",
          ],
        },
        kqzljcpt: {
          img: kqzljcxtjm,
          intro: "智能远程监控,终端数据可视化",
          title: "空气质量监测平台",
          url: "http://aqms.puwo-tech.com/",
          detailval: "snkqzljcxt",
          features: [
            "全能耗在线监测",
            "可视化数据呈现",
            "设备批量管理",
            "尖峰平谷报表查询",
            "APP移动终端服务",
            "能耗数据报表查询",
          ],
        },
        zhnhypt: {
          img: zhnhyptjm,
          intro: "智能远程监控,终端数据可视化",
          title: "综合能耗云平台",
          url: "http://energy.puwo-tech.com/",
          detailval: "zhnhfwypt",
          features: [
            "全能耗在线监测",
            "可视化数据呈现",
            "设备批量管理",
            "尖峰平谷报表查询",
            "APP移动终端服务",
            "能耗数据报表查询",
          ],
        },
        gfywypt: {
          img: gfywyptjm,
          intro: "智能远程监控,终端数据可视化",
          title: "光伏运维云平台",
          url: "http://pv.puwo-tech.com/",
          detailval: "gffdjkxt",
          features: [
            "全能耗在线监测",
            "可视化数据呈现",
            "设备批量管理",
            "尖峰平谷报表查询",
            "APP移动终端服务",
          ],
        },
      },
    };
  },
  computed: {
    curSoftInfo() {
      return this.softinfoList[this.cursoftVal];
    },
  },
  methods: {
    goDetailPage(deviceid) {
      this.$router.push({
        name: "SoftwareDetail",
        params: {
          deviceid,
        },
      });
    },
    goOhterWeb(url) {
      window.open(url);
    },
    goProductCenter(val, val1) {
      this.$router.push({
        name: "Products",
        params: {
          curFatherType: val,
          curSonType: val1,
        },
      });
    },
    goDetail(str) {
      this.$router.push(str);
    },
    chooseSoft(index, val) {
      this.cursoftVal = val;
      this.softList[index].choose = true;
      this.softList.map((item, idx) => {
        if (idx == index) {
          return;
        } else {
          item.choose = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.products {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.gscphanzi {
  text-align: center;
  font-size: 60px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2b2b2b;
  line-height: 23px;
  margin: 128px auto 85px auto;
  z-index: 999;
}
.gscp {
  position: absolute;
  top: 102px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  font-family: Helvetica;
  font-weight: bold;
  color: #cccccc;
  line-height: 23px;
  opacity: 0.6;
  z-index: 1;
}
.software_title {
  width: 80%;
  margin: 0 auto;
  font-size: 20px;
  font-family: "Poppins", "PingFang SC", "微软雅黑", sans-serif;
  font-weight: 400;
  word-break: normal;
  word-wrap: normal;
  cursor: pointer;
  margin-bottom: 12px;
}
.software_title:hover {
  color: #235cc7;
}
// 百叶窗
.box {
  width: 80%;
  height: 490px;
  margin: 20px auto;
  overflow: hidden;
}
li {
  width: 20%;
  height: 490px;
  display: inline-block;
  list-style: none;
  position: relative;
  transition: all 0.4s;
  .sys_name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    line-height: 36px;
    height: 36px;
    color: #fff;
    text-align: center;
  }

  .sys_msg {
    width: 250px;
    height: 333px;
    position: absolute;
    left: 2.3%;
    top: 3%;
    // transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    border-radius: 4px;
    .sys_title {
      font-family: "Poppins", "PingFang SC", "微软雅黑", sans-serif;
      font-weight: 400;
      word-break: normal;
      word-wrap: normal;
      font-size: 24px;
      color: #fff;
      width: 90%;
      padding: 10px 0;
      margin: 0 auto;
      margin-top: 4%;
      border-bottom: 1px solid #fafafa;
    }
    .sys_des {
      width: 90%;
      margin: 0 auto;
      font-size: 16px;
      color: #fff;
      margin-top: 10px;
      line-height: 2;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-family: Microsoft YaHei;
    }
    .learn_more {
      text-align: center;
      box-sizing: border-box;
      background: #e5eef2;
      line-height: 42px;
      padding: 0 10px;
      color: #fff;
      font-size: 12px;
      min-width: 0px;
      max-width: 120px;
      margin-top: 30px;
      margin-bottom: 0px;
      margin-left: 5%;
      background-color: rgba(184, 205, 228, 0);
      border-style: solid;
      border-color: rgb(245, 240, 240);
      border-width: 1px;
      cursor: pointer;
    }
    .learn_more:hover {
      background-color: #fff;
      color: #235cc7;
    }
  }
}

.box:hover li {
  width: 5%;
}
.box li:hover {
  width: 80%;
  .sys_msg {
    opacity: 1;
    visibility: visible;
    transition: 0.4s;
  }
}

// 硬件相关
.hard_box {
  width: 75vw;
  margin: 1vw auto;
  overflow: hidden;
  .hard_content {
    display: flex;
    justify-content: space-between;
    .yj_img_cls {
      width: 18.18vw;
    }
    .hard_item {
      width: 18.18vw;
      cursor: pointer;
      transition: 0.4s;
      box-shadow: 0px 0.2vw 0.2vw 0px rgba(204, 204, 204, 0.61);
      margin-bottom: 1vw;

      .hard_name {
        width: 18.18vw;
        background-color: #fff;
        font-size: 16px;
        font-family: Montserrat, Montserrat;
        font-weight: 500;
        color: #000000;
        text-align: center;
        padding: 0.5vw 0 0.8vw 0;
      }
    }
    .hard_item:hover {
      transform: scale(1.02);
      transition: 0.4s;
    }
  }
}
// 新版css样式
.box_32 {
  width: 75vw;
  height: 3.91vw;
  margin: 4vw auto 1vw;
}

.box_3 {
  background-color: rgba(52, 57, 192, 1);
  width: 0.21vw;
  height: 3.44vw;
  margin-top: 0.16vw;
}

.text-wrapper_49 {
  width: 25.79vw;
  height: 3.91vw;
  margin-left: 0.94vw;
}

.text_29 {
  width: 11.57vw;
  height: 1.72vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 1.25vw;
  letter-spacing: 0.6000000238418579px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 1.72vw;
}

.text_30 {
  width: 25.79vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 1.77vw;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 2.19vw;
}

.text_31 {
  width: 2.24vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.83vw;
  letter-spacing: 0.4000000059604645px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 2.18vw 0 0 45.83vw;
  cursor: pointer;
}
// tabs样式
.tabs_box {
  width: 100vw;
  border-bottom: 0.1vw solid #ebebeb;
}
.tabs_soft {
  width: 75vw;
  margin: 1.5vw auto 0;
  display: flex;
  overflow: auto;
  border-bottom: 1px solid #ebebeb;
  .tab_item_box {
    margin-right: 5vw;
    cursor: pointer;
    .soft_item_box {
      padding: 0 2.5vw 1vw 0.5vw;
      border-bottom: 0.2vw solid #3439c0;
    }
    .no_selected {
      border-bottom: 0;
    }
    .soft_name {
      font-size: 0.83vw;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
      margin-top: 0.8vw;
    }
  }
  .img_cls {
    width: 2vw;
    height: 2vw;
  }
}

// 软件tabs下详情css
.section_9 {
  width: 75vw;
  margin: 1vw auto;
  display: flex;
}
.img_box_cls {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vw;
}
.intro_box_cls {
  width: 40%;
  height: 20vw;
  padding: 1vw 0.5vw;
}
.image_3 {
  width: 43vw;
  height: 18vw;
}

.text_14 {
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.83vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
}

.text_15 {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 1.45vw;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 2.09vw;
  margin-top: 0.5vw;
}

.text_16 {
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  margin: 1.5vw 0 1vw 0;
}

.section_33 {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
}

.feature_item {
  width: 49%;
  margin-bottom: 1vw;
  display: flex;
  align-items: center;
}

.thumbnail_1 {
  width: 0.79vw;
  height: 0.79vw;
  margin-right: 0.45vw;
}

.feature_txt {
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 0.83vw;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 0.83vw;
}

.section_36 {
  width: 17.61vw;
  height: 1.9vw;
  margin-top: 1.83vw;
}

.text-wrapper_4 {
  background-color: rgba(52, 57, 192, 1);
  border-radius: 18px;
  height: 1.9vw;
  width: 7.19vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.text_17 {
  color: rgba(255, 255, 255, 1);
  font-size: 0.72vw;
  text-align: center;
  white-space: nowrap;
}

.section_10 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 18px;
  width: 8.86vw;
  height: 1.9vw;
  border: 1px solid rgba(52, 57, 192, 1);
  cursor: pointer;
}

.text_18 {
  overflow-wrap: break-word;
  color: rgba(52, 57, 192, 1);
  font-size: 0.72vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  margin: 0.4vw 0 0 0.98vw;
}

.image_4 {
  width: 1.15vw;
  height: 0.32vw;
  margin: 0.72vw 1.19vw 0 1.14vw;
}

// 物联网硬件产品
.box_38 {
  width: 75vw;
  height: 3.91vw;
  margin: 2vw auto;
}

.section_14 {
  background-color: rgba(52, 57, 192, 1);
  width: 0.21vw;
  height: 3.44vw;
  margin-top: 0.16vw;
}

.text-wrapper_50 {
  width: 21.52vw;
  height: 3.91vw;
  margin-left: 0.94vw;
}

.text_41 {
  width: 9.02vw;
  height: 1.72vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 1.25vw;
  letter-spacing: 0.6000000238418579px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 1.72vw;
}

.text_42 {
  width: 21.52vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: rgba(45, 45, 45, 1);
  font-size: 1.77vw;
  text-transform: uppercase;
  font-family: Montserrat-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 2.19vw;
}

.text_43 {
  width: 2.24vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.83vw;
  letter-spacing: 0.4000000059604645px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 2.18vw 0 0 50.1vw;
  cursor: pointer;
}
</style>