<template>
  <div class="support_box" id="support_box">
    <!-- <div class="title">
      <div class="fwyzz">SUPPORT</div>
      <div class="fwyzzhz">服务与支持</div>
    </div> -->
    <div class="support_body">
      <div class="support_msg">
        <div class="support_name">服务与支持</div>
        <div class="support_des">
          提供全面的电气相关服务与支持，包括设备安装、维护，故障排除，电路设计和优化。
          提供专业团队将确保您的电气系统始终高效运行，并提供快速响应和可靠解决方案，以满足您的需求。
        </div>
        <div class="support_iconfun">
          <el-tooltip placement="top">
            <div slot="content" style="line-height: 2;">
              全国热线：17721323870<br />
              固话：021-39882683<br />
              邮箱：puwo888@163.com
            </div>
            <div class="iconfun_item">
              <div class="icon_cls">
                <img src="../../assets/image/support1.png" alt="" />
              </div>
              <div class="fun_name">联系方式</div>
            </div>
          </el-tooltip>
          <div
            class="iconfun_item"
            @click="
              () => {
                this.$router.push('/service-case');
              }
            "
          >
            <div class="icon_cls">
              <img src="../../assets/image/support2.png" alt="" />
            </div>
            <div class="fun_name">服务案例</div>
          </div>
          <div
            class="iconfun_item"
            @click="
              () => {
                this.$router.push('/cooperation');
              }
            "
          >
            <div class="icon_cls">
              <img src="../../assets/image/support3.png" alt="" />
            </div>
            <div class="fun_name">渠道合作</div>
          </div>
          <!-- <div class="iconfun_item">
            <div class="icon_cls">
              <img src="../../assets/image/support4.png" alt="" />
            </div>
            <div class="fun_name">文件下载</div>
          </div> -->
        </div>
      </div>
      <div class="img_cls">
        <img src="../../assets/image/support.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.support_box {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background-color: #f8f8f8;
  margin-bottom: 20px;
}
.fwyzzhz {
  text-align: center;
  font-size: 60px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2b2b2b;
  line-height: 23px;
  margin: 128px auto 65px auto;
  z-index: 999;
}
.fwyzz {
  position: absolute;
  top: 102px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  font-family: Helvetica;
  font-weight: bold;
  color: #cccccc;
  line-height: 23px;
  opacity: 0.6;
  z-index: 1;
}

.support_body {
  //   width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  .img_cls {
    width: 600px;
    height: 360px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .support_msg {
    width: 600px;
    height: 360px;
    background-color: #fff;
    padding-top: 50px;
    padding-left: 60px;
    box-sizing: border-box;
    .support_name {
      font-size: 20px;
      line-height: 40px;
      text-align: left;
      color: #333333;
      font-weight: bold;
      font-family: Microsoft YaHei;
      height: 40px;
    }
    .support_des {
      width: 480px;
      height: 90px;
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      color: #666666;
      font-weight: normal;
      font-family: Microsoft YaHei;
      font-style: normal;
      margin-top: 20px;
    }
    .support_iconfun {
      display: flex;
      margin-top: 36px;
      width: 480px;
      justify-content: space-around;
      position: relative;
      .iconfun_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .icon_cls {
          width: 44px;
          height: 44px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .fun_name {
          margin-top: 0px;
          padding-top: 9px;
          font-size: 14px;
          color: #666666;
          height: 40px;
          line-height: 40px;
          font-family: Microsoft YaHei;
          background: transparent;
        }
      }
    }
  }
}
</style>