<template>
  <div class="content">
    <!-- 大图片 -->
    <div class="toptu">
      <el-carousel height="41.15vw">
        <el-carousel-item v-for="item in 4" :key="item">
          <div class="block_1 flex-col" v-show="item == 1">
            <div class="text-wrapper_43 flex-row">
              <span class="text_10">成为智能科技的创新者和引领者</span>
            </div>
            <div class="text-wrapper_44 flex-row">
              <span class="paragraph_1">
                建筑能源管理领域解决方案
                <br />
                建筑数字化孪生平台提供商
              </span>
            </div>
            <div class="group_34 flex-row">
              <div class="section_3 flex-col"></div>
            </div>
            <div
              class="group_35 flex-row"
              @click="goProductCenter('nyszhjjfa', 'rjall')"
            >
              <div class="section_5 flex-row justify-between">
                <span class="text_11">MORE</span>
                <img
                  class="image_2"
                  referrerpolicy="no-referrer"
                  src="../assets/index/right_arrow.png"
                />
              </div>
            </div>
          </div>
          <img
            class="lun_img_cls"
            v-show="item == 2"
            src="../assets/index/lun2.png"
            alt=""
          />
          <img
            class="lun_img_cls"
            v-show="item == 3"
            src="../assets/index/lun3.png"
            alt=""
          />
          <img
            class="lun_img_cls"
            v-show="item == 4"
            src="../assets/index/lun4.png"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="category_box">
      <div class="box_43 flex-row">
        <div class="image-text_32 flex-row justify-between">
          <img
            class="image_29"
            referrerpolicy="no-referrer"
            src="../assets/index/szhjjfa.png"
          />
          <div class="text-group_59 flex-col justify-between">
            <span
              class="text_130"
              @click="goProductCenter('nyszhjjfa', 'rjall')"
              >数字化解决方案</span
            >
            <div class="text-wrapper_54 flex-row justify-between">
              <span
                class="text_131"
                @click="goProductCenter('nyszhjjfa', 'rjnhgl')"
                >能耗管理</span
              >
              <span
                class="text_132"
                @click="goProductCenter('nyszhjjfa', 'rjdljk')"
                >电力监控</span
              >
              <span
                class="text_133"
                @click="goProductCenter('nyszhjjfa', 'rjhjjc')"
                >环境监测</span
              >
            </div>
          </div>
        </div>
        <span class="text_134" @click="goProductCenter('nyszhjjfa', 'rjlkjzm')"
          >楼控及照明</span
        >
      </div>
      <div class="box_43 flex-row">
        <div class="image-text_33 flex-row justify-between">
          <img
            class="image_30"
            referrerpolicy="no-referrer"
            src="../assets/index/wlwyjcp.png"
          />
          <div class="text-group_60 flex-col justify-between">
            <span
              class="text_135"
              @click="goProductCenter('nywlwyjcp', 'yjall')"
              >物联网硬件产品</span
            >
            <div class="text-wrapper_55 flex-row justify-between">
              <span
                class="text_136"
                @click="goProductCenter('nywlwyjcp', 'yjdlyb')"
                >电力仪表</span
              >
              <span
                class="text_137"
                @click="goProductCenter('nywlwyjcp', 'yjsnkqcgq')"
                >室内空气传感器</span
              >
            </div>
          </div>
        </div>
        <span class="text_138" @click="goProductCenter('nywlwyjcp', 'yjtxsb')"
          >通讯设备</span
        >
      </div>
      <div class="box_43 flex-row">
        <div class="image-text_34 flex-row justify-between">
          <img
            class="image_31"
            referrerpolicy="no-referrer"
            src="../assets/index/fwal.png"
          />
          <div class="text-group_61 flex-col">
            <span class="text_139" @click="goServiceCase('')">服务案例</span>
            <div class="text-wrapper_56 flex-row justify-between">
              <span class="text_140" @click="goServiceCase('syzht')"
                >商业综合体</span
              >
              <span class="text_141" @click="goServiceCase('jyyl')"
                >教育医疗</span
              >
            </div>
            <div class="text-wrapper_57 flex-row justify-between">
              <span class="text_142" @click="goServiceCase('gkqy')"
                >工矿企业</span
              >
              <span class="text_143" @click="goServiceCase('gfcn')"
                >光伏储能</span
              >
            </div>
          </div>
        </div>
        <span class="text_144" @click="goServiceCase('zhyq')">智慧园区</span>
        <span class="text_145" @click="goServiceCase('jdgy')">酒店公寓</span>
      </div>
    </div>
    <!-- 中间内容 -->
    <Product />
    <!-- 底部 -->
    <div class="fangan" id="hyyy_box">
      <div class="fayingyu">INDUSTRY APPLICATION</div>
      <div class="fahanzi">行业应用</div>
      <div class="anli">
        <div
          class="anli_box"
          @mouseover="changeAnliBg(1)"
          @click="goServiceCase('syzht')"
        >
          <div class="anli_msg">
            <div class="anli_typename">商业综合体</div>
            <div class="typename_en">Commercial complex</div>
            <div class="anli_icon_cls">
              <img loading="lazy" src="../assets/image/anliicon2.png" alt="" />
            </div>
            <div class="default_hidden">
              <div class="anli_thumbnail_cls">
                <img loading="lazy" src="../assets/index/hysyzht.png" alt="" />
              </div>
              <span class="more_cls">了解更多</span>
            </div>
          </div>
        </div>
        <div
          class="anli_box"
          @mouseover="changeAnliBg(2)"
          @click="goServiceCase('jyyl')"
        >
          <div class="anli_msg">
            <div class="anli_typename">教育医疗</div>
            <div class="typename_en">Education and healthcare</div>
            <div class="anli_icon_cls">
              <img loading="lazy" src="../assets/image/anliicon1.png" alt="" />
            </div>
            <div class="default_hidden">
              <div class="anli_thumbnail_cls">
                <img loading="lazy" src="../assets/image/yl.png" alt="" />
              </div>
              <span class="more_cls">了解更多</span>
            </div>
          </div>
        </div>
        <div
          class="anli_box"
          @mouseover="changeAnliBg(3)"
          @click="goServiceCase('zhyq')"
        >
          <div class="anli_msg">
            <div class="anli_typename">智慧园区</div>
            <div class="typename_en">Smart Park</div>
            <div class="anli_icon_cls">
              <img loading="lazy" src="../assets/image/anliicon3.png" alt="" />
            </div>
            <div class="default_hidden">
              <div class="anli_thumbnail_cls">
                <img loading="lazy" src="../assets/image/zhyq.png" alt="" />
              </div>
              <span class="more_cls">了解更多</span>
            </div>
          </div>
        </div>
        <div
          class="anli_box"
          @mouseover="changeAnliBg(4)"
          @click="goServiceCase('jdgy')"
        >
          <div class="anli_msg">
            <div class="anli_typename">酒店公寓</div>
            <div class="typename_en">Hotel apartment</div>
            <div class="anli_icon_cls">
              <img loading="lazy" src="../assets/image/anliicon4.png" alt="" />
            </div>
            <div class="default_hidden">
              <div class="anli_thumbnail_cls">
                <img loading="lazy" src="../assets/image/jdgy.png" alt="" />
              </div>
              <span class="more_cls">了解更多</span>
            </div>
          </div>
        </div>
        <div
          class="anli_box"
          @mouseover="changeAnliBg(5)"
          @click="goServiceCase('gkqy')"
        >
          <div class="anli_msg">
            <div class="anli_typename">工矿企业</div>
            <div class="typename_en">Industrial and mining enterprises</div>
            <div class="anli_icon_cls">
              <img loading="lazy" src="../assets/image/anliicon5.png" alt="" />
            </div>
            <div class="default_hidden">
              <div class="anli_thumbnail_cls">
                <img loading="lazy" src="../assets/image/gkqy.png" alt="" />
              </div>
              <span class="more_cls">了解更多</span>
            </div>
          </div>
        </div>
        <div
          class="anli_box"
          @mouseover="changeAnliBg(6)"
          @click="goServiceCase('gfcn')"
        >
          <div class="anli_msg">
            <div class="anli_typename">光伏储能</div>
            <div class="typename_en">Photovoltaic energy storage</div>
            <div class="anli_icon_cls">
              <img loading="lazy" src="../assets/image/anliicon6.png" alt="" />
            </div>
            <div class="default_hidden">
              <div class="anli_thumbnail_cls">
                <img loading="lazy" src="../assets/image/gfcn.png" alt="" />
              </div>
              <span class="more_cls">了解更多</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <about />
    <!-- 服务与支持 -->
    <!-- <support /> -->
  </div>
</template>

<script>
import anli1 from "../assets/index/hysyzht.png";
import anli2 from "../assets/image/yl.png";
import anli3 from "../assets/image/zhyq.png";
import anli4 from "../assets/image/jdgy.png";
import anli5 from "../assets/image/gkqy.png";
import anli6 from "../assets/image/gfcn.png";
import Product from "./indexCom/Product1.vue";
import Support from "./indexCom/Support1.vue";
import About from "./indexCom/About.vue";

export default {
  components: {
    Product,
    Support,
    About,
  },
  data() {
    return {
      top_img: { background: "" },
      back_img: { background: "" },
      product_list: [],
      solution: [],
      lunImgs: [],
      observer: null,
      changeTimer: null,
    };
  },
  mounted() {
    // const hash = this.$route.hash;
    // if (hash) {
    //   const targetElement = document.getElementById(hash.substring(1));
    //   if (targetElement) {
    //     targetElement.scrollIntoView();
    //   }
    // }
    this.getIndexInfo();
    window.addEventListener("scroll", this.windowScrollListener);
    this.observeDoms(".support_title");
    this.observeDoms(".support_des");
    this.observeDoms(".support_btn");
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScrollListener);
    this.removeObserve(".support_title");
    this.removeObserve(".support_des");
    this.removeObserve(".support_btn");
  },
  methods: {
    changeItem(curindex) {
      clearTimeout(this.changeTimer);
      if (this.lunImgs[curindex].type == 2) {
        this.$refs.videoPlayer[0].pause();
        // 由于手动切换时，定时器已在队列里，会导致视频未播放完就切换，或者事件不足4s时切换
        this.changeTimer = setTimeout(() => {
          this.$refs.carousel.next();
        }, 5000);
      } else {
        // this.$refs.videoPlayer.addEventListener("loadedmetadata", () => {
        //   this.$refs.videoPlayer.currentTime = 0;
        //   this.$refs.videoPlayer.play();
        // });
        this.$nextTick(() => {
          this.$refs.videoPlayer[0].currentTime = 0;
          this.$refs.videoPlayer[0].play();
        });
      }
    },
    nextItem() {
      // 自动切换到下一个项
      const currentIndex = this.lunImgs.findIndex((item) => item.type == "1");
      const nextIndex = (currentIndex + 1) % this.lunImgs.length;
      this.$refs.carousel.setActiveItem(nextIndex);
    },
    goId(val) {
      document.querySelector("#" + val).scrollIntoView(true);
    },
    goServiceCase(str) {
      this.$router.push({
        name: "ServiceCase",
      });
      setTimeout(() => {
        document.querySelector("#" + str).scrollIntoView(true);
      }, 800);
      // this.$router.push({
      //   path: "/service-case",
      //   query: {
      //     anchor: str, //  指定锚点
      //   },
      // });
    },
    removeObserve(selector) {
      let doms = document.querySelectorAll(selector);
      doms.forEach((dom) => {
        this.observer.unobserve(dom);
        this.observer.disconnent();
      });
    },
    observeDoms(selector) {
      let doms = document.querySelectorAll(selector);
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          entries[0].target.style.opacity = 1;
          entries[0].target.style.transition = "0.5s";
          entries[0].target.style.transform = "translateY(0px)";
        }
      });
      doms.forEach((dom) => {
        this.observer.observe(dom);
      });
    },
    goProductCenter(val, val1) {
      this.$router.push({
        name: "Products",
        params: {
          curFatherType: val,
          curSonType: val1,
        },
      });
    },
    async getIndexInfo() {
      const res = await this.axios.get("/jsondata/lunbo.json");
      this.lunImgs = res.data.imgs;
      // this.top_img.background = `url(${res.data.data.img.top_img})`;
      // this.back_img.background = `url(${res.data.data.img.back_img})`;
      // this.product_list = res.data.data.product_list;
      // this.solution = res.data.data.solution.slice(0, 3);
    },
    jump1() {
      this.$router.push("/fangan");
    },
    //监听窗口滚动
    windowScrollListener() {
      let dom = document.getElementsByClassName("anli");
      //获取操作元素最顶端到页面顶端的垂直距离
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 1100) {
        dom[0].style.margin = "207px auto auto";
      }
      if (scrollTop < 1100) {
        dom[0].style.margin = "300px auto auto";
      }
    },
    // 改变案例box总的背景图
    changeAnliBg(val) {
      let dom = document.getElementsByClassName("anli");
      // let anli1 = require("../assets/image/anli1.jpg");
      // let anli2 = require("../assets/image/anli2.jpg");
      // let anli1 = import("../assets/image/anli1.jpg");
      // let anli2 = import("../assets/image/anli2.jpg");
      // anli2.then((val) => {
      //   console.log(val.default);
      // });
      // console.log(anli1, 999);
      if (val == 1) {
        // dom[0].style.backgroundImage =
        //   "url(https://omo-oss-image.thefastimg.com/portal-saas/new2022071915060710800/cms/image/c0823335-f2ad-45cc-86f4-ba0aacd3c957.jpg)";
        dom[0].style.backgroundImage = "url(" + anli1 + ")";
        dom[0].style.opactiy = "1";
      } else if (val == 2) {
        dom[0].style.backgroundImage = "url(" + anli2 + ")";
        // dom[0].style["background-image"] =
        //   "url(https://omo-oss-image.thefastimg.com/portal-saas/new2022071915060710800/cms/image/74ca0e1e-2d6c-45fd-b5f9-245f0484c428.jpg)";
        dom[0].style.opactiy = "1";
      } else if (val == 3) {
        dom[0].style.backgroundImage = "url(" + anli3 + ")";
        dom[0].style.opactiy = "1";
      } else if (val == 4) {
        dom[0].style.backgroundImage = "url(" + anli4 + ")";
        dom[0].style.opactiy = "1";
      } else if (val == 5) {
        dom[0].style.backgroundImage = "url(" + anli5 + ")";
        dom[0].style.opactiy = "1";
      } else if (val == 6) {
        dom[0].style.backgroundImage = "url(" + anli6 + ")";
        dom[0].style.opactiy = "1";
      }
      dom[0].style.transition = "0.3s";
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #ffffff;
}

.router-link-active {
  text-decoration: none;
}
.content {
  width: 100%;
  background-color: #fff;
}
.toptu {
  /* width: 100%; */
  background: no-repeat top left;
  background-size: 100% 100%;
  position: relative;
}

.fangan {
  width: 100vw;
  height: 910px;
  margin-top: 12px;
  background: no-repeat top left;
  background-size: 100% 100%;
  background-image: url("~@/assets/image/fabg4.png");
  position: relative;
  overflow: hidden;
  background-attachment: fixed;
}
.fayingyu {
  // width: 346px;
  height: 45px;
  font-size: 60px;
  font-family: Helvetica;
  font-weight: bold;
  color: #cccccc;
  line-height: 23px;
  opacity: 0.6;
  position: absolute;
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.fahanzi {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  height: 59px;
  font-size: 60px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 23px;
}

img {
  width: 100%;
  height: 100%;
}

.anli {
  width: 75vw;
  height: 594px;
  margin: 300px auto auto;
  transition: 1s;
  display: flex;
  justify-content: space-between;
  background: no-repeat top left;
  background-size: 100% 100%;
  background-image: url(../assets/index/hysyzht.png);
  overflow: hidden;
}
.start_position {
  width: 75vw;
  height: 594px;
  // background-color: #ccc;
  margin: 300px auto auto;
  transition: 1s;
}
.anli_box {
  width: 12.5vw;
  height: 594px;
  // height: 1500px;
  background: rgba(226, 0, 0, 0);
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  background: no-repeat top left;
  background-position: 0px 50px;
  background-size: 100% 100%;
  background-image: url("~@/assets/image/anlibg.png");
  cursor: pointer;
  transition: 0.6s;
  color: #fff;
  position: relative;
  overflow: hidden;
  // animation-duration: 3s;
  // animation-delay: 0.5s;
  // animation-fill-mode: both;
  .anli_msg {
    position: absolute;
    top: 423px;
    left: 1.88vw;
    z-index: 852;
    transition: 0.5s;

    .anli_typename {
      font-size: 26px;
      font-family: "微软雅黑";
      font-weight: bold;
      letter-spacing: 1px;
      margin-bottom: 8px;
    }
    .typename_en {
      font-size: 18px;
      font-family: "arial";
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .anli_icon_cls {
      height: 30px;
      width: 30px;
      margin-top: 16px;
    }
    .default_hidden {
      visibility: hidden;
      opacity: 0;
      margin-top: 20px;
      transition: 0.5s;
    }
    .anli_thumbnail_cls {
      width: 160px;
      height: 75px;
      margin-bottom: 30px;
    }
    .more_cls {
      font-size: 16px;
      padding: 6px 20px;
      font-weight: lighter;
      border: solid 1px #fff;
    }
  }
}
.anli_box:last-child {
  width: 241px;
  border: none;
}
.anli_box:hover {
  // background: no-repeat bottom left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px -50px;
  background-image: url("~@/assets/image/anlibg.png");
  background-color: #004da0;
  transition: 0.6s;
  transition-timing-function: ease;
  .anli_msg {
    top: 150px;
    transition: 0.5s;
  }
  .default_hidden {
    visibility: visible;
    opacity: 1;
    transition: 0.5s;
  }
}

// .anli_box:hover:nth-child(1) ~ .anli {
//   background-image: url(../assets/image/anli1.jpg) !important;
// }
// .anli_box:hover:nth-child(2) ~ .anli {
//   background-image: url(https://omo-oss-image.thefastimg.com/portal-saas/new2022071915060710800/cms/image/74ca0e1e-2d6c-45fd-b5f9-245f0484c428.jpg) !important;
// }
// .anli_box:hover:nth-child(3) ~.anli {
//   background-image: url(../assets/image/anli3.jpg) !important;
// }

// .anli:has(.anli_box:nth-child(3):hover){
//   background-image: url(../assets/image/anli3.jpg) !important;
// }

.video_box {
  padding-top: 90px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .video_cls {
    width: 100%;
    height: 100%;
    object-fit: fill !important;
  }
}
.img_box {
  width: 100%;
  height: 100%;
  // box-sizing: border-box;
}
// 新版样式
.block_1 {
  background-image: url(../assets/index/lun1.png);
  background-size: cover;
  height: 41.15vw;
  width: 100vw;
}
.lun_img_cls {
  height: 41.15vw;
  width: 100vw;
}
.text-wrapper_43 {
  width: 18.96vw;
  height: 2.77vw;
  margin: 7.96vw 0 0 12.5vw;
}

.text_10 {
  height: 2.77vw;
  overflow-wrap: break-word;
  color: rgba(142, 155, 180, 1);
  font-size: 1.35vw;
  font-family: OPPOSans-R;
  font-weight: NaN;
  text-align: left;
  line-height: 2.77vw;
}

.text-wrapper_44 {
  width: 30.79vw;
  height: 6.25vw;
  margin: 0.78vw 0 0 12.5vw;
}

.paragraph_1 {
  height: 6.25vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 2.5vw;
  letter-spacing: 1.2000000476837158px;
  font-family: OPPOSans-H;
  font-weight: NaN;
  text-align: left;
  line-height: 3.13vw;
}

.group_34 {
  width: 9.38vw;
  height: 0.21vw;
  margin: 1.92vw 0 0 12.5vw;
}

.section_3 {
  background-color: rgba(255, 123, 52, 1);
  width: 9.38vw;
  height: 0.21vw;
}

.group_35 {
  width: 8.34vw;
  height: 1.88vw;
  margin: 5vw 0 0 12.5vw;
  cursor: pointer;
}

.section_5 {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 18px;
  width: 8.34vw;
  height: 1.88vw;
  border: 1px solid rgba(255, 255, 255, 1);
}

.text_11 {
  width: 2.14vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.72vw;
  font-family: OPPOSans-R;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 0.94vw;
  margin: 0.46vw 0 0 1.14vw;
}

.image_2 {
  width: 2.3vw;
  height: 0.89vw;
  margin: 0.52vw 1.14vw 0 0;
}

.group_36 {
  width: 21.57vw;
  height: 0.21vw;
  margin: 4.63vw 0 6.56vw 12.5vw;
}

.group_1 {
  background-color: rgba(255, 255, 255, 0.5);
  width: 6.25vw;
  height: 0.11vw;
  margin-top: 0.06vw;
}

.group_2 {
  background-color: rgba(255, 255, 255, 0.5);
  width: 6.25vw;
  height: 0.11vw;
  margin: 0.05vw 0 0 1.4vw;
}

.group_3 {
  background-color: rgba(255, 255, 255, 1);
  width: 6.25vw;
  height: 0.21vw;
  margin-left: 1.41vw;
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 16%;
  left: 20.5vw;
}
::v-deep .el-carousel__button {
  width: 5vw;
}

// 轮播图下的3个分类css

.category_box {
  width: 75vw;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  margin-top: -2.44vw;
  display: flex;
  justify-content: space-between;
}
.box_43 {
  width: 24.17vw;
  height: 7.87vw;
  background-color: #fff;
  border-radius: 0.4vw;
  box-shadow: 0 0.1vw 0.2vw 0 rgba(0, 0, 0, 0.1);
}

.image-text_32 {
  width: 16.72vw;
  height: 5.06vw;
  margin: 1.4vw 0 0 1.25vw;
}

.image_29 {
  width: 5.06vw;
  height: 5.06vw;
}

.text-group_59 {
  width: 10.63vw;
  height: 3.6vw;
  margin-top: 0.53vw;
}

.text_130 {
  width: 8.23vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 1.14vw;
  letter-spacing: 0.550000011920929px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  cursor: pointer;
}

.text-wrapper_54 {
  width: 10.63vw;
  height: 1.05vw;
  margin-top: 0.99vw;
}

.text_131 {
  width: 3.03vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  cursor: pointer;
}

.text_132 {
  width: 3.03vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  cursor: pointer;
  margin-left: 0.79vw;
}

.text_133 {
  width: 3.03vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  cursor: pointer;
  margin-left: 0.79vw;
}

.text_134 {
  width: 3.75vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  cursor: pointer;
  margin: 4.47vw 1.66vw 0 0.78vw;
}

.box_44 {
  width: 24.17vw;
  height: 7.87vw;
  background: url(../assets/index/bg2.png) -0.73vw -0.73vw no-repeat;
  background-size: 26.25vw 9.94vw;
}

.image-text_33 {
  width: 15.11vw;
  height: 5.16vw;
  margin: 1.35vw 0 0 1.3vw;
}

.image_30 {
  width: 5.16vw;
  height: 5.16vw;
}

.text-group_60 {
  width: 9.07vw;
  height: 3.6vw;
  margin-top: 0.58vw;
}

.text_135 {
  width: 8.23vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 1.14vw;
  letter-spacing: 0.550000011920929px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.57vw;
}

.text-wrapper_55 {
  width: 9.07vw;
  height: 1.05vw;
  margin-top: 0.99vw;
}

.text_136 {
  width: 3.03vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.05vw;
}

.text_137 {
  width: 5.27vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.05vw;
}

.text_138 {
  width: 3.03vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  cursor: pointer;
  margin: 4.47vw 3.95vw 0 0.78vw;
}

.box_45 {
  width: 24.17vw;
  height: 7.87vw;
  background: url(../assets/index/bg2.png) -0.73vw -0.73vw no-repeat;
  background-size: 26.25vw 9.94vw;
}

.image-text_34 {
  width: 13.49vw;
  height: 5vw;
  margin: 1.45vw 0 0 1.4vw;
}

.image_31 {
  width: 5vw;
  height: 5vw;
}

.text-group_61 {
  width: 7.56vw;
  height: 4.54vw;
  margin-top: 0.47vw;
}

.text_139 {
  width: 4.74vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 1.14vw;
  letter-spacing: 0.550000011920929px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.57vw;
}

.text-wrapper_56 {
  width: 7.56vw;
  height: 1.05vw;
  margin-top: 0.58vw;
}

.text_140 {
  width: 3.75vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.05vw;
}

.text_141 {
  width: 3.03vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.05vw;
}

.text-wrapper_57 {
  width: 6.83vw;
  height: 1.05vw;
  margin-top: 0.32vw;
}

.text_142 {
  width: 3.03vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.05vw;
}

.text_143 {
  width: 3.03vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.05vw;
}

.text_144 {
  width: 3.03vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  cursor: pointer;
  margin: 4.06vw 0 0 0.78vw;
}

.text_145 {
  width: 3.03vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  letter-spacing: 0.3499999940395355px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  cursor: pointer;
  margin: 4.06vw 1.66vw 0 0.78vw;
}
</style>
