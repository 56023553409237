import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // redirect: "/index",
    redirect: to => {
      // 方法接收目标路由作为参数
      // return 重定向的字符串路径/路径对象
      if (window.screenWidth >= 1200) {
        return { path: '/index' }
      } else {
        return { path: '/mainpage' }
      }
    },
  },
  {
    // pc首页
    path: '/index',
    name: 'Index',
    component: Index
  },
  {
    // pc产品中心
    path: '/products/:curFatherType/:curSonType',
    name: 'Products',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fangan" */ '../views/Products.vue')
  },
  {
    // pc服务案例
    path: '/service-case',
    name: 'ServiceCase',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fangan" */ '../views/ServiceCase.vue')
  },
  {
    // pc渠道合作
    path: '/cooperation',
    name: 'Cooperation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fangan" */ '../views/Cooperation.vue')
  },
  {
    // pc硬件产品详情
    path: '/product-detail/:deviceid',
    name: 'ProductDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fangan" */ '../views/ProductDetail.vue')
  },
  {
    // pc软件产品详情
    path: '/software-detail/:deviceid',
    name: 'SoftwareDetail',
    component: () => import(/* webpackChunkName: "fangan" */ '../views/SoftwareDetail.vue')
  },
  {
    // pc关于我们
    path: '/company-intro',
    name: 'CompanyIntro',
    component: () => import(/* webpackChunkName: "about" */ '../views/CompanyIntro.vue')
  },
  {
    // h5首页
    path: '/mainpage',
    name: 'Main',
    component: () => import(/* webpackChunkName: "about" */ '../views_h5/pages/main.vue')
  },
  {
    // h5产品中心
    path: '/productpage/:curFatherType/:curSonType',
    name: 'H5Product',
    component: () => import(/* webpackChunkName: "about" */ '../views_h5/pages/product.vue')
  },
  {
    // h5软件详情
    path: '/h5softwaredetailpage/:deviceid',
    name: 'H5SoftwareDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views_h5/pages/softwaredetail.vue')
  },
  {
    // h5硬件详情
    path: '/h5productdetailpage/:deviceid',
    name: 'H5ProductDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views_h5/pages/productdetail.vue')
  },
  {
    // h5服务案例
    path: '/h5servicecase/:caseType',
    name: 'H5ServiceCase',
    component: () => import(/* webpackChunkName: "about" */ '../views_h5/pages/servicecase.vue')
  },
  {
    // h5渠道合作
    path: '/h5contact',
    name: 'H5Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views_h5/pages/contact.vue')
  },
  {
    // h5关于我们
    path: '/aboutuspage',
    name: 'H5Aboutus',
    component: () => import(/* webpackChunkName: "about" */ '../views_h5/pages/aboutus.vue')
  },
]

const h5routes = [
  {
    path: '/',
    // redirect: "/index",
    redirect: to => {
      // 方法接收目标路由作为参数
      // return 重定向的字符串路径/路径对象
      if (window.screenWidth >= 1200) {
        return { path: '/index' }
      } else {
        return { path: '/mainpage' }
      }
    },
  },
  {
    path: '/mainpage',
    name: 'Main',
    component: () => import(/* webpackChunkName: "about" */ '../views_h5/pages/main.vue')
  },
  {
    path: '/productpage',
    name: 'H5Product',
    component: () => import(/* webpackChunkName: "about" */ '../views_h5/pages/product.vue')
  },

]


const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  // routes
  base: '/',
  mode: 'hash',
  routes
})
// 跳转页面时，默认将滚动条滚到最上方
let previousPath = null;
router.beforeEach((to, from, next) => {
  // console.log(window.screenWidth, 558);
  // 将上一个路由保存到变量中
  previousPath = from.path;
  window.sessionStorage.setItem("previousPath", previousPath)
  // 将当前路由保存到变量中
  window.sessionStorage.setItem("routeName", to.name)
  next();
});

router.afterEach((to, from, next) => {
  // 参数顺序x轴，y轴
  window.scrollTo(0, 0);
});

export default router
